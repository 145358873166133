/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  jsx-a11y/anchor-is-valid */
/** @jsxRuntime classic */
/** @jsx jsx */
import { Divider, List, Typography } from "antd";
import React, {
  Component,
  Fragment,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { browserName, browserVersion } from "react-device-detect";
import { css, jsx } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";

import Axios from "axios";
import { Base64 } from "js-base64";
import { Categories } from "interfaces/category";
import { Link } from "react-router-dom";
import TrendingGamesBox from "./HomeTrendingGames/TrendingGamesBox";
import _ from "lodash";
import { buildServiceUrl } from "common/helper";
import config from "config.js";
import { getCategoriesAPI } from "api/api";
import log from "common/log.js";
import randomstring from "randomstring";
import { selectCategories } from "features/categories/categorySlice";
import { selectConfig } from "features/config/configSlice";
import useDeviceQuery from "common/device";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

export default function HomeTrendingGames() {
  const { isDesktopOrLaptop, isTablet, isMobile, isIpadMini } =
    useDeviceQuery();

  const [categories, setCategories] = useState<Categories|null>(null);

  const allCategories: Categories = useSelector((state) => selectCategories(state));
  const siteConfig = useSelector((state) => selectConfig(state));

  let catIds: number[] = useMemo(() => [], []);
  if (siteConfig) {
    catIds = siteConfig.trending_categories.split(",").map((e: string) => parseInt(e));
  }

  useEffect(() => {
    if (catIds && allCategories.length > 0) {
      let cats: any = [];
      allCategories.forEach((element: any) => {
        if (catIds?.includes(element.id)) {
          cats.push(element);
        }
      });

      setCategories(cats);
    }
  }, [allCategories, categories, catIds]);

  if (categories) {
    let cat1, cat2, cat3;

    if (catIds?.length >= 1) {
      cat1 = categories.find((cat: any) => cat.id === catIds[0]);
    }

    if (catIds?.length >= 2) {
      cat2 = categories.find((cat: any) => cat.id === catIds[1]);
    }

    if (catIds?.length >= 3) {
      cat3 = categories.find((cat: any) => cat.id === catIds[2]);
    }

    return (
      <div className="container mx-auto mt-8 home-trending-games">
        <div className="text-3xl text-center mb-8">Trending Games</div>
        <div className="bm-columns">
          {cat1 ? (
            <div className="bm-column">
              <TrendingGamesBox
                headerClassName="box1"
                data={{
                  service: cat1.name,
                  category: cat1,
                }}
              />
            </div>
          ) : (
            ""
          )}

          {cat2 ? (
            <div className="bm-column">
              <TrendingGamesBox
                headerClassName="box2"
                data={{
                  service: cat2.name,
                  category: cat2,
                }}
              />
            </div>
          ) : (
            ""
          )}

          {cat3 ? (
            <div className="bm-column">
              <TrendingGamesBox
                headerClassName="box3"
                data={{
                  service: cat3.name,
                  category: cat3,
                }}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }

  return <Fragment></Fragment>;
}
