/** @jsxRuntime classic */
/** @jsx jsx */
/* eslint-disable no-unused-vars */
/* eslint-disable no-extend-native */
/// <reference types="@emotion/react/types/css-prop" />
import "./assets/main.css";
import "./assets/bulma.css";
import "./App.less";
import "remixicon/fonts/remixicon.css";

import {
  BrowserRouter,
  HashRouter,
  Link,
  Route,
  Switch,
} from "react-router-dom";
import {
  MessengerChat,
  setMessengerBottomSpacing,
} from "react-messenger-chat-plugin";
import React, { Component, Fragment, useState } from "react";
import { css, jsx } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";

import CategoryDetail from "controllers/Category/index";
import Home from "controllers/Home/index";
import Login from "controllers/Auth/Login";
import PackageDetail from "controllers/Package/index";
import Policy from "controllers/Page/Policy";
import PropTypes from "prop-types";
import Signup from "controllers/Auth/Signup";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import Terms from "controllers/Page/Terms";
import TransactionDetail from "controllers/User/TransactionDetail";
import Transactions from "controllers/User/Transactions";
import Trending from "controllers/Trending/index";
import { selectAuth } from "features/auh/authSlice";
import { tawkMessengerRef } from "controllers/components/tawkto";
import { useRef } from "react";

const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);
  return React.createElement(component, finalProps);
};

const PropsRoute = ({ component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(routeProps) => {
        return renderMergedProps(component, routeProps, rest);
      }}
    />
  );
};

export default function App() {
  const currentAuth = useSelector((state) => selectAuth(state));

  const tawkMessengerRef = useRef();
  // setMessengerBottomSpacing(200);

  return (
    <React.Fragment>
      <div className={"boxgem"}>
        <BrowserRouter>
          <Switch>
            <PropsRoute
              exact
              key="home"
              path="/"
              name="home"
              component={Home}
            />

            <PropsRoute
              exact
              key="policy"
              path="/policy"
              name="policy"
              component={Policy}
            />

            <PropsRoute
              exact
              key="terms"
              path="/terms"
              name="terms"
              component={Terms}
            />

            <PropsRoute
              exact
              key="login"
              path="/login"
              name="login"
              component={Login}
            />

            <PropsRoute
              exact
              key="signup"
              path="/signup"
              name="signup"
              component={Signup}
            />

            <PropsRoute
              exact
              key="/trending/:categoryId"
              path="/trending/:categoryId"
              name="/trending/:categoryId"
              component={Trending}
            />

            <PropsRoute
              exact
              key="/services/:serviceId/:serviceSlug"
              path="/services/:serviceId/:serviceSlug"
              name="/services/:serviceId/:serviceSlug"
              component={CategoryDetail}
            />

            <PropsRoute
              exact
              key="/packages/:serviceId/:serviceSlug/:packageId"
              path="/packages/:serviceId/:serviceSlug/:packageId"
              name="/packages/:serviceId/:serviceSlug/:packageId"
              component={PackageDetail}
            />

            {currentAuth.token ? (
              <Fragment>
                <PropsRoute
                  exact
                  key="/user/transactions"
                  path="/user/transactions"
                  name="/user/transactions"
                  component={Transactions}
                />

                <PropsRoute
                  exact
                  key="/user/transaction/:transactionId"
                  path="/user/transaction/:transactionId"
                  name="/user/transaction/:transactionId"
                  component={TransactionDetail}
                />
              </Fragment>
            ) : (
              <Fragment></Fragment>
            )}
          </Switch>
        </BrowserRouter>
        {/* <TawkMessengerReact
          propertyId="61398f24d326717cb6807e6f"
          widgetId="1ff4dea8v"
          ref={tawkMessengerRef}
        /> */}

        <MessengerChat pageId="2309407065996748" greetingDialogDisplay="icon" />
      </div>
    </React.Fragment>
  );
}

PropsRoute.propTypes = {
  component: PropTypes.func,
};
