export function buildServiceUrl(service) {
  return `/services/${service.id}/${service.slug}`;
}

export function buildPackageUrl(service, servicePackage) {
  return `/packages/${service.id}/${service.slug}/${servicePackage.id}`;
}

export function scrollTop() {
  try {
    // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  } catch (error) {
    // just a fallback for older browsers
    window.scrollTo(0, 0);
  }
}
