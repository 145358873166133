/* eslint-disable no-unused-vars */
import XXH from "xxhashjs";
import _ from "lodash";
import randomstring from "randomstring";
let xxsalt = 0xabcd;
const hostname = window && window.location && window.location.hostname;
const prefix = "[BOXGEM]";
const debug = hostname === "localhost" ? true : false;
const backend = "https://boxgembackend.apisquad.com"

let config = {
  encrypt: true,
  debug: hostname === "localhost" ? true : false,
  app_hostname: hostname,
  root_path: "/",
  backend: "https://boxgembackend.apisquad.com",
  api: {
    get_categories: `${backend}/api/categories`,
    get_services: `${backend}/api/services`,
    get_trendings: `${backend}/api/trending`,
    get_packages: `${backend}/api/packages`,
    get_service: `${backend}/api/service`,
    get_package: `${backend}/api/package`,
    get_config: `${backend}/api/config`,
    post_register: `${backend}/api/register`,
    post_login: `${backend}/api/login`,
    get_profile: `${backend}/api/profile`,
    post_buy: `${backend}/api/buy`,
    get_transactions: `${backend}/api/transactions`,
    get_transaction: `${backend}/api/transaction`,
    post_verify_token: `${backend}/api/verify-token`,
    add_user_image: `${backend}/api/add-images`,
  }
};

export default config;
