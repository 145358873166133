/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import log from "common/log.js";

const initialState = {
  data: localStorage.getItem("browse_histories")
    ? JSON.parse(localStorage.getItem("browse_histories"))
    : [],
  status: "idle",
  error: null,
};

const browseSlice = createSlice({
  name: "browse_histories",
  initialState,
  reducers: {
    clearHistory: (state) => {
      state.data = [];
      localStorage.setItem("browse_histories", JSON.stringify(state.data));
    },
    pushHistory: (state, action) => {
      let inHistory = false;

      console.log("Action: ", action);

      if (state.data.length > 0) {
        state.data.forEach((item) => {
          if (
            item.service.id === action.payload.service.id &&
            item.item.id === action.payload.item.id
          ) {
            inHistory = true;
          }
        });
      }

      console.log("inHistory: ", inHistory);

      if (!inHistory) {
        if (state.data.length === 3) state.data.shift();
        state.data.push(action.payload);
        localStorage.setItem("browse_histories", JSON.stringify(state.data));
      }
    },
  },
  extraReducers: {},
});

export default browseSlice.reducer;
export const selectBrowseHistories = (state) => state.browse.data;
export const { clearHistory, pushHistory } = browseSlice.actions;
