/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/** @jsxRuntime classic */
/** @jsx jsx */
import { Divider, List, Typography } from "antd";
import React, {
  Component,
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { browserName, browserVersion } from "react-device-detect";
import { css, jsx } from "@emotion/react";

import Axios from "axios";
import { Base64 } from "js-base64";
import Footer from "controllers/components/Footer";
import Header from "controllers/components/Header";
import HomeBenefits from "./HomeBenefits";
import HomeBrowseHistories from "./HomeBrowseHistories";
import HomePaymentMethods from "./HomePaymentMethods";
import HomeSearchBox from "./HomeSearchBox";
import HomeServices from "./HomeServices";
import HomeTrendingGames from "./HomeTrendingGames";
import { Link } from "react-router-dom";
import _ from "lodash";
import config from "config.js";
import { getCategories } from "features/categories/categorySlice";
import { getConfig } from "features/config/configSlice";
import { getUserProfile } from "features/auh/authSlice";
import { scrollTop } from "common/helper";
import store from "app/store";
import useDeviceQuery from "common/device";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

store.dispatch(getCategories());
store.dispatch(getConfig());
store.dispatch(getUserProfile());

export default function Home() {
  useEffect(() => {
    scrollTop();
  }, []);

  const {
    isDesktopOrLaptop,
    isTablet,
    isMobile,
    isIpadMini,
    isMobileAndIpadMini,
  } = useDeviceQuery();

  if (isDesktopOrLaptop) {
    return (
      <Fragment>
        <Header />
        <HomeSearchBox />
        <HomeServices />
        <HomeBrowseHistories />
        <HomeTrendingGames />
        <HomeBenefits />
        <HomePaymentMethods />
        <Footer />
      </Fragment>
    );
  }

  if (isTablet) {
    return (
      <Fragment>
        <Header />
        <HomeServices />
        <HomeBrowseHistories />
        <HomeTrendingGames />
        <HomeBenefits />
        <HomePaymentMethods />
        <Footer />
      </Fragment>
    );
  }

  if (isMobile) {
    return (
      <Fragment>
        <Header />
        <HomeServices />
        <HomeBrowseHistories />
        <HomeTrendingGames />
        <HomeBenefits />
        <HomePaymentMethods />
        <Footer />
      </Fragment>
    );
  }
}
