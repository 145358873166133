export interface UserTransactions {
  meta: Meta;
  data: Daum[];
}

export interface Meta {
  total: number;
}

export interface Daum {
  transaction: Transaction;
  details: Detail[];
}

export interface Transaction {
  id: number;
  state: number;
  note: string;
  reply: any;
  images: string;
  user_images: string;
  created_at: string;
  updated_at: string;
  total_price: number;
}

export interface Detail {
  item: Item;
  package: Package;
  service: Service;
}

export interface Item {
  id: number;
  package_id: number;
  transaction_id: number;
  price: number;
  discount: number;
  quantity: number;
  username: string;
  password: string;
  login_method: any;
  fa_code: string;
  server: string;
  character: string;
  type: string;
  state: number;
  updated_at: string;
}

export interface Package {
  id: number;
  name: string;
  description: string;
  service_id: number;
  value: number;
  user_price: number;
  partner_price: number;
  discount: number;
  quantity: number;
  eta: string;
  sold: number;
  slug: string;
  unit_label: string;
}

export interface Service {
  id: number;
  name: string;
  image: any;
  description: string;
  category_id: number;
  sold_packages: number;
  slug: string;
}

export const TransactionStates: any = {
  0: "Cancelled",
  1: "Pending",
  2: "Processing",
  3: "Completed",
};
