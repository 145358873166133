/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/** @jsxRuntime classic */
/** @jsx jsx */
import { Divider, List, Typography } from "antd";
import React, {
  Component,
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import ReactHtmlParser, {
  convertNodeToElement,
  processNodes,
} from "react-html-parser";
import { browserName, browserVersion } from "react-device-detect";
import { css, jsx } from "@emotion/react";
import { getPackageAPI, getPackagesAPI, getServiceAPI } from "api/api";
import { useHistory, useParams } from "react-router-dom";

import { Base64 } from "js-base64";
import CategoryHeader from "./CategoryHeader";
import Footer from "controllers/components/Footer";
import Header from "controllers/components/Header";
import { Link } from "react-router-dom";
import PackageGrid from "controllers/components/PackageGrid";
import { Service } from "interfaces/service";
import _ from "lodash";
import config from "config.js";
import log from "common/log.js";
import randomstring from "randomstring";
import { scrollTop } from "common/helper";
import useDeviceQuery from "common/device";
import { useMediaQuery } from "react-responsive";

interface ParamTypes {
  serviceId?: string | undefined; serviceSlug?: string | undefined;
}

export default function CategoryDetail() {
  const {
    isDesktopOrLaptop,
    isTablet,
    isMobile,
    isIpadMini,
    isMobileAndIpadMini,
  } = useDeviceQuery();

  let { serviceId, serviceSlug } = useParams<ParamTypes>();
  const [service, setService] = useState<Service | null>(null);
  const [servicePackages, setServicePackages] = useState(null);

  useEffect(() => {
    scrollTop();
  }, [])

  useEffect(() => {


    /////
    getServiceAPI({
      params: {
        serviceId: serviceId,
      },
    })
      .then((response) => {
        setService(response.data);
      })
      .catch((error) => {
        log(error);
      });

    /////
    getPackagesAPI({
      params: {
        serviceId: serviceId,
      },
    })
      .then((response) => {
        setServicePackages(response.data);
      })
      .catch((error) => {
        log(error);
      });
  }, [serviceId]);

  return (
    <Fragment>
      <Header showBack={true} pageTitle={service?.name} />
      {service ? <CategoryHeader service={service} /> : ""}

      <div className="container mx-auto mt-8 home-trending-games pb-16">
        {servicePackages ? (
          <PackageGrid
            data={servicePackages}
            service={service}
            pagination={true}
          />
        ) : (
          ""
        )}

        {service?.description ? (
          <Fragment>
            <Divider css={{ margin: "12px 0 24px 0" }} />

            {isMobile || isIpadMini ? (
              <div css={{ paddingRight: 20, paddingLeft: 20 }}>
                <div className="text-md font-bold mb-6">
                  About {service?.name}:
                </div>
                {ReactHtmlParser(service.description)}
              </div>
            ) : (
              <Fragment>
                <div className="text-md font-bold mb-6">
                  About {service?.name}:
                </div>
                {ReactHtmlParser(service.description)}
              </Fragment>
            )}
          </Fragment>
        ) : (
          ""
        )}
      </div>

      <Footer />
    </Fragment>
  );
}
