/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */

/** @jsxRuntime classic */
/** @jsx jsx */
import { Divider, List, Typography } from "antd";
import React, {
  Component,
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { browserName, browserVersion } from "react-device-detect";
import { css, jsx } from "@emotion/react";

import { Base64 } from "js-base64";
import { Service } from "interfaces/service";
import _ from "lodash";
import { buildServiceUrl } from "common/helper";
import config from "config.js";
import log from "common/log.js";
import randomstring from "randomstring";
import useDeviceQuery from "common/device";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

export function serviceImage(item: Service) {
  if(item) {
    return item?.image && item?.image !== "" ? `url(https://boxgembackend.apisquad.com/public/uploads/services/${item.image})` : `url(https://picsum.photos/52/52?random=${item.id})`
  }
  return `url(https://picsum.photos/52/52)`
}

export default function GameGrid(props: any) {
  let history = useHistory();
  const {
    isDesktopOrLaptop,
    isTablet,
    isMobile,
    isIpadMini,
    isMobileAndIpadMini,
  } = useDeviceQuery();

  return (
    <React.Fragment>
      {isDesktopOrLaptop ? (
        <div className="grid grid-cols-4 gap-4 game-grid pb-16">
          {props.data.map((item: Service, index: number) => {
            return (
              <div
                className="rounded-md border border-gray-300 p-4
                            item hover:shadow-lg duration-500 bg-white"
                css={{ zIndex: 1, cursor: "pointer" }}
                onClick={() => {
                  history.push(buildServiceUrl(item));
                }}
              >
                <div className="flex flex-row">
                  <div
                    className="rounded-md mr-2"
                    css={{
                      width: 28,
                      height: 28,
                      backgroundImage: serviceImage(item),
                      backgroundRepeat: "none",
                      backgroundSize: "cover",
                    }}
                  ></div>
                  <div className="flex flex-col ">
                    <div css={{ height: 52 }}>
                      <strong>{item?.name}</strong>
                    </div>
                    <div className="text-gray-600">
                      {item?.sold_packages} Sold
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        ""
      )}

      {isTablet ? (
        <div
          className="grid grid-cols-3 gap-4 game-grid pb-16"
          css={{ padding: "0 20px" }}
        >
          {props.data.map((item: Service, index: number) => {
            return (
              <div
                className="rounded-md border border-gray-300 p-4
                              item hover:shadow-lg duration-500 bg-white"
                css={{ zIndex: 1, cursor: "pointer" }}
                onClick={() => {
                  history.push(buildServiceUrl(item));
                }}
              >
                <div className="flex flex-row">
                  <div
                    className="rounded-md mr-2"
                    css={{
                      width: 28,
                      height: 28,
                      backgroundImage: serviceImage(item),
                      backgroundRepeat: "none",
                      backgroundSize: "cover",
                    }}
                  ></div>
                  <div className="flex flex-col ">
                    <div css={{ height: 52 }}>
                      <strong>{item?.name}</strong>
                    </div>
                    <div className="text-gray-600">
                      {item?.sold_packages} Sold
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        ""
      )}

      {isMobile ? (
        <div
          className="flex flex-col game-grid pb-16"
          css={{ padding: "0 20px" }}
        >
          {props.data.map((item: Service, index: number) => {
            return (
              <div
                className="rounded-md border border-gray-300 p-4
                              item hover:shadow-lg duration-500 bg-white mb-4"
                css={{ zIndex: 1, cursor: "pointer" }}
                onClick={() => {
                  history.push(buildServiceUrl(item));
                }}
              >
                <div className="flex flex-row">
                  <div
                    className="rounded-md mr-2"
                    css={{
                      width: "28px !important",
                      height: 28,
                      backgroundImage: serviceImage(item),
                      backgroundRepeat: "none",
                      backgroundSize: "cover",
                    }}
                  ></div>
                  <div className="flex flex-col ">
                    <div css={{ height: 52 }}>
                      <strong>{item?.name}</strong>
                    </div>
                    <div className="text-gray-600">
                      {item?.sold_packages} Sold
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        ""
      )}

      {/* {props.pagination ? (
        <nav
          className="bm-pagination bm-is-small"
          role="navigation"
          aria-label="pagination"
        >
          <a className="bm-pagination-previous">Previous</a>
          <a className="bm-pagination-next">Next page</a>
          <ul className="bm-pagination-list">
            <li>
              <a className="bm-pagination-link" aria-label="Goto page 1">
                1
              </a>
            </li>
            <li>
              <span className="bm-pagination-ellipsis">&hellip;</span>
            </li>
            <li>
              <a className="bm-pagination-link" aria-label="Goto page 45">
                45
              </a>
            </li>
            <li>
              <a
                className="bm-pagination-link is-current"
                aria-label="Page 46"
                aria-current="page"
              >
                46
              </a>
            </li>
            <li>
              <a className="bm-pagination-link" aria-label="Goto page 47">
                47
              </a>
            </li>
            <li>
              <span className="bm-pagination-ellipsis">&hellip;</span>
            </li>
            <li>
              <a className="bm-pagination-link" aria-label="Goto page 86">
                86
              </a>
            </li>
          </ul>
        </nav>
      ) : (
        ""
      )} */}
    </React.Fragment>
  );
}
