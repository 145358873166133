/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/** @jsxRuntime classic */
/** @jsx jsx */
import { Divider, List, Typography } from "antd";
import React, {
  Component,
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { browserName, browserVersion } from "react-device-detect";
import { css, jsx } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";

import Axios from "axios";
import { Base64 } from "js-base64";
import { Link } from "react-router-dom";
import _ from "lodash";
import config from "config.js";
import log from "common/log.js";
import paymentAmex from "assets/images/payments/amex.png";
import paymentMC from "assets/images/payments/mastercard-kb.jpg";
import paymentVisa from "assets/images/payments/visaeghl.png";
import randomstring from "randomstring";
import { selectConfig } from "features/config/configSlice";
import useDeviceQuery from "common/device";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

export default function HomePaymentMethods(props: any) {
  const { isDesktopOrLaptop, isTablet, isMobile, isIpadMini } =
    useDeviceQuery();
  const siteConfig = useSelector((state) => selectConfig(state));
  return (
    <div className="container mx-auto mt-8 home-trending-games pb-16">
      <div className="text-3xl text-center mb-8">
        Payment Methods
        <div className="text-sm">
          Find out how to pay in the most convenient way.
        </div>
      </div>

      <div className="bm-columns">

        {siteConfig?.payment_methods_json ? JSON.parse(siteConfig?.payment_methods_json).map((col: any) => {
          return <div className="bm-column">
            <div
              className="rounded-md border border-gray-300 p-3"
              css={isDesktopOrLaptop ? { height: 188 } : { paddingBottom: 30 }}
            >
              <div>{col?.title}</div>
              <div className="grid grid-cols-4 gap-4 mt-8">

                {
                  col?.images?.map((link: any) => {
                    return <div>

                      <img src={siteConfig?.backend_url + "/public/uploads/" +link} alt="icon" width={60} />

                    </div>
                  })
                }

              </div>
            </div>
          </div>


        }) : ""}



      </div>
    </div>
  );
}
