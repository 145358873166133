/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/** @jsxRuntime classic */
/** @jsx jsx */
import { Divider, List, Typography } from "antd";
import React, {
  Component,
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { browserName, browserVersion } from "react-device-detect";
import { css, jsx } from "@emotion/react";

import Axios from "axios";
import { Base64 } from "js-base64";
import { Link } from "react-router-dom";
import _ from "lodash";
import config from "config.js";
import log from "common/log.js";
import randomstring from "randomstring";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

export default function HomeSearchBox(props:any) {
  return (
    <div className={"home-banner"}>
      <div className={"search-box-container"}></div>
    </div>
  );
}


