/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/** @jsxRuntime classic */
/** @jsx jsx */
import { Divider, List, Typography } from "antd";
import React, {
  Component,
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import ReactHtmlParser, {
  convertNodeToElement,
  processNodes,
} from "react-html-parser";
import { browserName, browserVersion } from "react-device-detect";
import { css, jsx } from "@emotion/react";

import { Base64 } from "js-base64";
import { Link } from "react-router-dom";
import PackageGrid from "controllers/components/PackageGrid";
import _ from "lodash";
import config from "config.js";
import log from "common/log.js";
import randomstring from "randomstring";
import useDeviceQuery from "common/device";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

export default function CategoryHeader(props:any) {
  const {
    isDesktopOrLaptop,
    isTablet,
    isMobile,
    isIpadMini,
    isMobileAndIpadMini,
  } = useDeviceQuery();

  let { service } = props;

  if (isDesktopOrLaptop) {
    return (
      <React.Fragment>
        <div className={"category-banner"}></div>
        <div className="bg-gray-200">
          <div className="container mx-auto pt-12 pb-12">
            <div className="text-3xl">{service?.name}</div>
            <div
              css={{ color: "#c2c2c2" }}
              className="sub-category-links"
            ></div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  if (isTablet) {
    return (
      <React.Fragment>
        <div className={"category-banner"}></div>
        <div className="bg-gray-200">
          <div
            className="container mx-auto pt-6 pb-6"
            css={{ paddingRight: 20, paddingLeft: 20 }}
          >
            <div className="text-3xl">{service?.name}</div>
            <div
              css={{ color: "#c2c2c2" }}
              className="sub-category-links"
            ></div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  if (isMobile) {
    return (
      <React.Fragment>
        <div className={"category-banner"}></div>
        <div className="bg-gray-200">
          <div
            className="container mx-auto pt-6 pb-6"
            css={{ paddingRight: 20, paddingLeft: 20 }}
          >
            <div className="text-xl pb-2">{service?.name}</div>
            <div
              css={{ color: "#c2c2c2" }}
              className="sub-category-links"
            ></div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  return <Fragment></Fragment>
}
