/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  jsx-a11y/anchor-is-valid */
/** @jsxRuntime classic */
/** @jsx jsx */
import {
  Affix,
  Breadcrumb,
  Button,
  Card,
  Col,
  Input,
  Layout,
  Menu,
  Row,
  Select,
} from "antd";
import { Divider, List, Typography } from "antd";
import { Package, PackageDataProps } from "interfaces/package";
import React, {
  Component,
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import ReactHtmlParser, {
  convertNodeToElement,
  processNodes,
} from "react-html-parser";
import { browserName, browserVersion } from "react-device-detect";
import { css, jsx } from "@emotion/react";

import { Base64 } from "js-base64";
import { InputNumber } from "antd";
import { Link } from "react-router-dom";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import _ from "lodash";
import config from "config.js";
import log from "common/log.js";
import randomstring from "randomstring";
import useDeviceQuery from "common/device";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

export default function PackageInfo(props: PackageDataProps) {
  const {
    isDesktopOrLaptop,
    isTablet,
    isMobile,
    isIpadMini,
    isMobileAndIpadMini,
  } = useDeviceQuery();

  let { service, servicePackage } = props;

  useEffect(() => {
  }, []);

  //
  // const handleShow = () => {
  //   console.log("handleShow");
  //   // @ts-ignore
  //   tawkMessengerRef.current.showWidget();


  // };

  return (
    <div
      className="rounded-md border border-gray-300 item duration-500 bg-white"
      css={{ minHeight: 500 }}
    >
      <div className="p-8">
        <div className="text-2xl font-bold text-blue-500">{service.name}</div>
        <div className="text-2xl">{servicePackage.name}</div>
        {/* {!isMobileAndIpadMini ? (
          <div
            onClick={handleShow}

            className="bm-button bm-is-medium bm-is-fullwidth mt-8"
          >
            <i className="fas fa-comment-dots text-green-500 mr-2"></i>Chat
          </div>
        ) : (
          ""
        )} */}

        <div className="pt-8">
          {ReactHtmlParser(servicePackage.description)}
        </div>
      </div>

    </div>
  );
}
