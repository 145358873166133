/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/** @jsxRuntime classic */
/** @jsx jsx */
import { Divider, List, Typography } from "antd";
import React, {
  Component,
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { browserName, browserVersion } from "react-device-detect";
import { css, jsx } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";

import Axios from "axios";
import { Base64 } from "js-base64";
import { Categories } from "interfaces/category";
import { Link } from "react-router-dom";
import _ from "lodash";
import config from "config.js";
import iconCoins from "assets/svg/coins.svg";
import iconExchange from "assets/svg/exchange.svg";
import iconFacebook from "assets/svg/facebook.svg";
import iconGiftCard from "assets/svg/gift-card.svg";
import iconGoogle from "assets/svg/google_icon_48.svg";
import log from "common/log.js";
import randomstring from "randomstring";
import { selectCategories } from "features/categories/categorySlice";
import store from "app/store";
import useDeviceQuery from "common/device";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

export default function HomeServices() {
  let history = useHistory();

  const {
    isDesktopOrLaptop,
    isTablet,
    isMobile,
    isIpadMini,
    isTabletWithoutIpadMini,
  } = useDeviceQuery();

  const categories: Categories = useSelector((state) => selectCategories(state));
  const catIds: number[] = categories.map((cat:any) => cat.id);

  let googleURL = "/trending/1";
  let facebookURL = "/trending/2";
  let topupURL = "/trending/3";
  let giftcardURL = "/trending/4";
  let exchangeURL = "/trending/5";

  if (isDesktopOrLaptop) {
    return (
      <div
        className="container mx-auto mt-8 home-services bg-white shadow-lg rounded-lg overflow-hidden"
        css={{ marginTop: -60 }}
      >
        <div className="bm-columns">
          {catIds.includes(1) ? (
            <div
              className="bm-column text-center service-item duration-500"
              onClick={() => {
                history.push(googleURL);
              }}
            >
              <div className="pt-4">
                <img alt="icon"
                  className="icon duration-500 gamecoins"
                  src={iconGoogle}
                ></img>
                Google Account
              </div>
            </div>
          ) : (
            ""
          )}
          {catIds.includes(2) ? (
            <div
              className="bm-column text-center service-item duration-500"
              onClick={() => {
                history.push(facebookURL);
              }}
            >
              <div className="pt-4">
                <img alt="icon"
                  src={iconFacebook}
                  className="icon duration-500 gameitems"
                ></img>
                Facebook Account
              </div>
            </div>
          ) : (
            ""
          )}

          {catIds.includes(3) ? (
            <div
              className="bm-column text-center font-semibold service-item duration-500"
              onClick={() => {
                history.push(topupURL);
              }}
            >
              <div className="pt-4">
                <img src={iconCoins} alt="icon" className="icon duration-500 topup"></img>
                Topup
              </div>
            </div>
          ) : (
            ""
          )}

          {catIds.includes(4) ? (
            <div
              className="bm-column text-center service-item duration-500"
              onClick={() => {
                history.push(giftcardURL);
              }}
            >
              <div className="pt-4">
                <img alt="icon"
                  src={iconGiftCard}
                  className="icon duration-500 gamepal"
                ></img>
                Gift Card
              </div>
            </div>
          ) : (
            ""
          )}

          {catIds.includes(5) ? (
            <div
              className="bm-column text-center service-item duration-500"
              onClick={() => {
                history.push(exchangeURL);
              }}
            >
              <div className="pt-4">
                <img alt="icon"
                  src={iconExchange}
                  className="icon duration-500 accounts"
                ></img>
                Exchange
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }

  if (isTabletWithoutIpadMini) {
    return (
      <div
        className="container mx-auto mt-8 home-services bg-white shadow-lg rounded-lg overflow-hidden"
        css={{ marginTop: 92 }}
      >
        <div className="bm-columns">
          {catIds.includes(1) ? (
            <div
              className="bm-column text-center service-item duration-500"
              onClick={() => {
                history.push(googleURL);
              }}
            >
              <div className="pt-4">
                <img alt="icon"
                  className="icon duration-500 gamecoins"
                  src={iconGoogle}
                ></img>
                Google Account
              </div>
            </div>
          ) : (
            ""
          )}

          {catIds.includes(2) ? (
            <div
              className="bm-column text-center service-item duration-500"
              onClick={() => {
                history.push(facebookURL);
              }}
            >
              <div className="pt-4">
                <img alt="icon"
                  src={iconFacebook}
                  className="icon duration-500 gameitems"
                ></img>
                Facebook Account
              </div>
            </div>
          ) : (
            ""
          )}

          {catIds.includes(3) ? (
            <div
              className="bm-column text-center font-semibold service-item duration-500"
              onClick={() => {
                history.push(topupURL);
              }}
            >
              <div className="pt-4">
                <img src={iconCoins} alt="icon" className="icon duration-500 topup"></img>
                Top up
              </div>
            </div>
          ) : (
            ""
          )}

          {catIds.includes(4) ? (
            <div
              className="bm-column text-center service-item duration-500"
              onClick={() => {
                history.push(giftcardURL);
              }}
            >
              <div className="pt-4">
                <img alt="icon"
                  src={iconGiftCard}
                  className="icon duration-500 gamepal"
                ></img>
                Gift Card
              </div>
            </div>
          ) : (
            ""
          )}
          {catIds.includes(5) ? (
            <div
              className="bm-column text-center service-item duration-500"
              onClick={() => {
                history.push(exchangeURL);
              }}
            >
              <div className="pt-4">
                <img alt="icon"
                  src={iconExchange}
                  className="icon duration-500 accounts"
                ></img>
                Exchange
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }

  if (isIpadMini) {
    return (
      <div
        className="container mx-auto mt-8 home-services bg-white overflow-hidden"
        css={{ marginTop: 72, paddingTop: 20, paddingBottom: 20 }}
      >
        <div className="grid grid-flow-row grid-cols-5 grid-rows-1">
          {catIds.includes(1) ? (
            <div
              className="text-center service-item duration-500"
              onClick={() => {
                history.push(googleURL);
              }}
            >
              <div className="pt-4">
                <img alt="icon"
                  className="icon duration-500 gamecoins"
                  src={iconGoogle}
                ></img>
                Google Account
              </div>
            </div>
          ) : (
            ""
          )}

          {catIds.includes(2) ? (
            <div
              className="text-center service-item duration-500"
              onClick={() => {
                history.push(facebookURL);
              }}
            >
              <div className="pt-4">
                <img alt="icon"
                  src={iconFacebook}
                  className="icon duration-500 gameitems"
                ></img>
                Facebook Account
              </div>
            </div>
          ) : (
            ""
          )}

          {catIds.includes(3) ? (
            <div
              className="text-center service-item duration-500"
              onClick={() => {
                history.push(topupURL);
              }}
            >
              <div className="pt-4">
                <img src={iconCoins} alt="icon" className="icon duration-500 topup"></img>
                Top up
              </div>
            </div>
          ) : (
            ""
          )}

          {catIds.includes(4) ? (
            <div
              className=" text-center service-item duration-500"
              onClick={() => {
                history.push(giftcardURL);
              }}
            >
              <div className="pt-4">
                <img alt="icon"
                  src={iconGiftCard}
                  className="icon duration-500 gamepal"
                ></img>
                Gift Card
              </div>
            </div>
          ) : (
            ""
          )}
          {catIds.includes(5) ? (
            <div
              className=" text-center service-item duration-500"
              onClick={() => {
                history.push(exchangeURL);
              }}
            >
              <div className="pt-4">
                <img alt="icon"
                  src={iconExchange}
                  className="icon duration-500 accounts"
                ></img>
                Exchange
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }

  if (isMobile) {
    return (
      <div
        className="container mx-auto mt-8 home-services bg-white overflow-hidden"
        css={{ marginTop: 72, paddingTop: 20, paddingBottom: 20 }}
      >
        <div className="grid grid-flow-row grid-cols-3 grid-rows-2">
          {catIds.includes(1) ? (
            <div
              className="text-center service-item duration-500"
              onClick={() => {
                history.push(googleURL);
              }}
            >
              <div className="pt-4">
                <img alt="icon"
                  className="icon duration-500 gamecoins"
                  src={iconGoogle}
                ></img>
                Google Account
              </div>
            </div>
          ) : (
            ""
          )}

          {catIds.includes(2) ? (
            <div
              className="text-center service-item duration-500"
              onClick={() => {
                history.push(facebookURL);
              }}
            >
              <div className="pt-4">
                <img alt="icon"
                  src={iconFacebook}
                  className="icon duration-500 gameitems"
                ></img>
                Facebook Account
              </div>
            </div>
          ) : (
            ""
          )}

          {catIds.includes(3) ? (
            <div
              className="text-center service-item duration-500"
              onClick={() => {
                history.push(topupURL);
              }}
            >
              <div className="pt-4">
                <img src={iconCoins} alt="icon" className="icon duration-500 topup"></img>
                Top up
              </div>
            </div>
          ) : (
            ""
          )}

          {catIds.includes(4) ? (
            <div
              className=" text-center service-item duration-500"
              onClick={() => {
                history.push(giftcardURL);
              }}
            >
              <div className="pt-4">
                <img
               alt="icon"
                  src={iconGiftCard}
                  className="icon duration-500 gamepal"
                ></img>
                Gift Card
              </div>
            </div>
          ) : (
            ""
          )}

          {catIds.includes(5) ? (
            <div
              className=" text-center service-item duration-500"
              onClick={() => {
                history.push(exchangeURL);
              }}
            >
              <div className="pt-4">
                <img
                  src={iconExchange} alt="icon"
                  className="icon duration-500 accounts"
                ></img>
                Exchange
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }

  return <Fragment></Fragment>;
}
