import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getUserProfileAPI,
  postUserLoginAPI,
  postUserSignupAPI,
} from "api/api";

import log from "common/log.js";

const initialState = {
  token: null,
  profile: null,
  status: "idle",
  error: null,
};

async function checkDataProfile(data, token) {
  let response = await getUserProfileAPI({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  let profile = response.data;
  log("Profile:", profile);
  log("Date:", data);

  if (data?.email === profile?.email && profile?.id) {
    localStorage.setItem("bearer", token);
    return {
      token: token,
      profile: profile,
    };
  } else {
    localStorage.clear();
    return {
      token: null,
      profile: null,
    };
  }
}

export const userLogout = createAsyncThunk("user/logout", async () => {
  localStorage.clear();
  window.location.reload();
  return {
    token: null,
    profile: null,
  };
});

export const getUserProfile = createAsyncThunk("user/profile", async (data) => {
  let token = localStorage.getItem("bearer");

  if (token) {
    return getUserProfileAPI({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((repsonse) => {
        if (repsonse.data.email && repsonse.data.id) {
          return {
            token: token,
            profile: repsonse.data,
          };
        } else {
          localStorage.clear();
          window.location.href = "/";
          return {
            token: null,
            profile: null,
          };
        }
      })
      .catch((error) => {
        log(error);
        return error;
      });
  } else {
    localStorage.clear();

    return {
      token: null,
      profile: null,
    };
  }
});

export const postUserLogin = createAsyncThunk("user/login", async (data) => {
  return postUserLoginAPI(data)
    .then(async (repsonse) => {
      return await checkDataProfile(data, repsonse.data.token);
    })
    .catch((error) => {
      log("Error:", error.response.data.errors[0].message);
      if (error.response.data.errors[0].message) {
        return error.response.data.errors[0].message;
      }
      return JSON.stringify(error.response.data);
    });
});

export const postUserSignup = createAsyncThunk("user/signup", async (data) => {
  return postUserSignupAPI(data)
    .then(async (repsonse) => {
      let res = await checkDataProfile(data, repsonse.data.token);
      if (res.profile) {
        window.location.href = "/";
      }
      return res;
    })
    .catch((error) => {
      log("Error:", error.response.data.errors[0].message);
      if (error.response.data.errors[0].message) {
        return error.response.data.errors[0].message;
      }
      return JSON.stringify(error.response.data);
    });
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: {
    [postUserSignup.pending]: (state, action) => {
      state.status = "loading";
    },
    [postUserSignup.fulfilled]: (state, action) => {
      state.status = "succeeded";

      log("Action Payload:", action.payload);

      if (action.payload?.profile) {
        state.token = action.payload.token;
        state.profile = action.payload.profile;
      } else {
        state.error = action.payload;
        state.token = null;
        state.profile = null;
      }
    },

    [postUserLogin.pending]: (state, action) => {
      state.status = "loading";
    },
    [postUserLogin.fulfilled]: (state, action) => {
      state.status = "succeeded";

      if (action.payload?.profile) {
        state.token = action.payload.token;
        state.profile = action.payload.profile;
      } else {
        state.error = action.payload;
        state.token = null;
        state.profile = null;
      }
    },
    [getUserProfile.pending]: (state, action) => {
      state.status = "loading";
    },
    [getUserProfile.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.token = action.payload.token;
      state.profile = action.payload.profile;
    },

    [userLogout.pending]: (state, action) => {
      state.status = "loading";
    },
    [userLogout.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.token = action.payload.token;
      state.profile = action.payload.profile;
    },
  },
});

export default authSlice.reducer;
export const selectAuth = (state) => state.auth;
