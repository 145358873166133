import { Categories, Category } from "interfaces/category";
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/** @jsxRuntime classic */
/** @jsx jsx */
import { Divider, List, Typography } from "antd";
import React, {
  Component,
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  browserName,
  browserVersion,
  isMobile,
  isTablet,
} from "react-device-detect";
import { css, jsx } from "@emotion/react";
import { getCategoriesAPI, getServicesAPI, getTrendingAPI } from "api/api";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { Base64 } from "js-base64";
import Footer from "controllers/components/Footer";
import GameGrid from "controllers/components/GameGrid";
import Header from "controllers/components/Header";
import { Input } from "antd";
import { Service } from "interfaces/service";
import { Services } from "interfaces/services";
import _ from "lodash";
import config from "config.js";
import log from "common/log.js";
import randomstring from "randomstring";
import { scrollTop } from "common/helper";
import { selectCategories } from "features/categories/categorySlice";
import useDeviceQuery from "common/device";
import { useMediaQuery } from "react-responsive";

interface ParamTypes {
  categoryId?: string | undefined;
}

export default function Trending() {
  const {
    isDesktopOrLaptop,
    isTablet,
    isMobile,
    isIpadMini,
    isMobileAndIpadMini,
  } = useDeviceQuery();

  let { categoryId } = useParams<ParamTypes>();

  const [category, setCategory] = useState<Category | null>(null);
  const [trendingServices, setTrendingServices] = useState(null);
  const [allServices, setAllServices] = useState<Service[] | null>(null);
  const [searchResults, setSearchResults] = useState<Service[] | null>(null);
  const [searchMode, setSearchMode] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");

  const allCategories: Categories = useSelector((state) => selectCategories(state));

  if (!category) {
    allCategories.forEach((element) => {
      if (parseInt(categoryId!) === element.id) {
        setCategory(element);
      }
    });
  }


  useEffect(() => {
    scrollTop();
  }, [])


  useEffect(() => {
    getTrendingAPI({
      params: {
        categoryId: categoryId,
        limit: 8,
      },
    })
      .then((repsonse) => {
        let data = repsonse.data;
        if (data.length > 0) {
          setTrendingServices(repsonse.data);
        }
      })
      .catch((error) => {
        log(error);
      });

    getServicesAPI({
      params: {
        categoryId: categoryId,
        excludeTrending: true,
      },
    })
      .then((repsonse) => {
        let data = repsonse.data.data;
        if (data.length > 0) {
          setAllServices(repsonse.data.data);
        }
      })
      .catch((error) => {
        log(error);
      });
  }, [categoryId]);

  const handleSearchInputChange = (e: any) => {
    let val = e.target.value;

    if (val.length > 0) {
      setSearchKeyword(val);
      setSearchMode(true);

      getServicesAPI({
        params: {
          categoryId: categoryId,
          search: val,
        },
      })
        .then((repsonse) => {
          let data = repsonse.data.data;
          if (data.length > 0) {
            setSearchResults(repsonse.data.data);
          } else {
            setSearchResults(null);
          }
        })
        .catch((error) => {
          log(error);
        });
    } else {
      setSearchResults(null);
      setSearchKeyword("");
      setSearchMode(false);
    }
  };

  return (
    <Fragment>
      <Header showBack={true} pageTitle={category?.name} />

      <div className="trending-game-coins pt-10">
        {isDesktopOrLaptop ? (
          <div className="icon-container">
            <div className="icon"></div>
          </div>
        ) : (
          ""
        )}

        <div className="container mx-auto" css={{ zIndex: 99 }}>
          {isDesktopOrLaptop ? (
            <Fragment>
              <div className="text-4xl text-center pt-8 mb-8">
                {category?.name}
                <div className="text-sm"></div>
                <div className="mt-4" css={{}}>
                  <Input
                    placeholder={`Search game in ${category?.name}`}
                    allowClear
                    onChange={handleSearchInputChange}
                  />
                </div>
              </div>
            </Fragment>
          ) : (
            ""
          )}
          {isTablet ? (
            <Fragment>
              <div className="text-4xl text-center pt-16 mb-8">
                {category?.name}
                <div className="text-sm"></div>
                <div
                  className="mt-4"
                  css={{ paddingLeft: 20, paddingRight: 20 }}
                >
                  <Input
                    placeholder={`Search game in ${category?.name}`}
                    allowClear
                    onChange={handleSearchInputChange}
                  />
                </div>
              </div>
            </Fragment>
          ) : (
            ""
          )}
          {isMobile ? (
            <Fragment>
              <div className="text-center pt-16 mb-8">
                {/* <div className="text-4xl">{category?.name}</div>
                <div className="text-sm"></div> */}
                <div css={{ paddingLeft: 20, paddingRight: 20 }}>
                  <Input
                    placeholder={`Search game in ${category?.name}`}
                    allowClear
                    onChange={handleSearchInputChange}
                  />
                </div>
              </div>
            </Fragment>
          ) : (
            ""
          )}

          {trendingServices && !searchMode ? (
            <Fragment>
              {isDesktopOrLaptop ? (
                <Fragment>
                  <div className="text-xl text-center pt-8 mb-8">
                    Trending Games
                    <div className="text-sm"></div>
                  </div>
                </Fragment>
              ) : (
                ""
              )}
              {isTablet ? (
                <Fragment>
                  <div className="text-xl text-center mb-8">
                    Trending Games
                    <div className="text-sm"></div>
                  </div>
                </Fragment>
              ) : (
                ""
              )}
              {isMobile ? (
                <Fragment>
                  <div className="text-xl text-center mb-8">
                    Trending Games
                    <div className="text-sm"></div>
                  </div>
                </Fragment>
              ) : (
                ""
              )}
              <GameGrid data={trendingServices} />{" "}
            </Fragment>
          ) : (
            <div css={{ minHeight: 300 }}>
              {searchResults && searchMode ? (
                <Fragment>
                  <div className="text-xl text-center mb-8 mt-8">
                    Search results for {searchKeyword}
                    <div className="text-sm"></div>
                  </div>
                  <GameGrid data={searchResults} pagination={true} />
                </Fragment>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      </div>

      <div className="container mx-auto mt-8 home-trending-games pb-16">
        {allServices && !searchMode ? (
          <Fragment>
            <div className="text-xl text-center mb-8 mt-8">
              All Games
              <div className="text-sm"></div>
            </div>
            <GameGrid data={allServices} pagination={true} />
          </Fragment>
        ) : (
          ""
        )}
      </div>

      <Footer />
    </Fragment>
  );
}
