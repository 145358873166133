/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/** @jsxRuntime classic */
/** @jsx jsx */
import { Divider, List, Typography } from "antd";
import React, {
  Component,
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { browserName, browserVersion } from "react-device-detect";
import { css, jsx } from "@emotion/react";

import Axios from "axios";
import { Base64 } from "js-base64";
import { Link } from "react-router-dom";
import _ from "lodash";
import config from "config.js";
import log from "common/log.js";
import randomstring from "randomstring";
import useDeviceQuery from "common/device";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

export default function HomeBenefits() {
  const { isDesktopOrLaptop, isTablet, isMobile, isIpadMini } =
    useDeviceQuery();

  return (
    <div className="mt-16 home-benefits">
      <div className="container mx-auto mt-8">
        <div className="bm-columns">
          <div className="bm-column text-center p-4">
            <div className="text-6xl mt-2">
              <i className="ri-shield-check-line"></i>
            </div>
            <div className="p-1 text-xl">
              <strong>Secure Transactions</strong>
              <div
                className="text-sm mt-4"
                css={{ paddingRight: 40, paddingLeft: 40 }}
              >
                Feel confident each time you transact with us. GamerProtect
                comes with SSL protection and wide range of payment processors
                under a safe and secured platform.
              </div>
            </div>
          </div>
          <div className="bm-column text-center p-4">
            <div className="text-6xl mt-2">
              <i className="ri-customer-service-2-fill"></i>
            </div>

            <div className="p-1 text-xl">
              <strong>Customer Support</strong>
              <div
                className="text-sm mt-4"
                css={{ paddingRight: 40, paddingLeft: 40 }}
              >
                Our dedicated Customer Service team are available to help with
                any queries about your orders and provide exceptional
                after-sales support.
              </div>
            </div>
          </div>
          <div className="bm-column text-center p-4">
            <div className="text-6xl mt-2">
              <i className="ri-vip-crown-2-line"></i>
            </div>
            <div className="p-1 text-xl">
              <strong>Best Offers</strong>
              <div
                className="text-sm mt-4"
                css={{ paddingRight: 40, paddingLeft: 40 }}
              >
                Boxgem provides competitive pricing to the buyers driven by a free
                market economy while striving to keep the cost low for our
                sellers.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
