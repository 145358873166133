/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/** @jsxRuntime classic */
/** @jsx jsx */

import { Divider, List, Typography } from "antd";
import { Field, Form, Formik, FormikErrors, FormikProps, FormikTouched, withFormik } from 'formik';
import React, {
  Component,
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { browserName, browserVersion } from "react-device-detect";
import { css, jsx } from "@emotion/react";
import { signInWithFacebook, signInWithGoogle } from "firebase";
import { useDispatch, useSelector } from "react-redux";

import { Base64 } from "js-base64";
import Footer from "controllers/components/Footer";
import Header from "controllers/components/Header";
import { Link } from "react-router-dom";
import _ from "lodash";
import config from "config.js";
import log from "common/log.js";
import { postUserLogin } from "features/auh/authSlice";
import randomstring from "randomstring";
import { scrollTop } from "common/helper";
import { selectAuth } from "features/auh/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import useDeviceQuery from "common/device";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

export default function Login() {
  const {
    isDesktopOrLaptop,
    isTablet,
    isMobile,
    isIpadMini,
    isMobileAndIpadMini,
  } = useDeviceQuery();

  let history = useHistory();
  const dispatch = useDispatch();
  const currentAuth = useSelector((state) => selectAuth(state));

  const formValues: LoginValues = {
    email: "", password: "",
  };

  useEffect(() => {
    scrollTop()
  }, []);

  if (currentAuth.profile && currentAuth.status === "succeeded") {
    history.push("/");
  }

  if (!currentAuth.profile && currentAuth.status === "succeeded") {
    return (
      <Fragment>
        <Header isAuthPage={true} />

        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-200">
          <div className="flex flex-col bg-white shadow-md px-4 sm:px-6 md:px-8 lg:px-10 py-8 rounded-md w-full max-w-md">
            <div
              className={`font-medium self-center text-xl sm:text-2xl text-gray-800 ${isMobile ? "mt-16" : ""
                }`}
            >
              Login
            </div>
            <button onClick={signInWithFacebook} className="relative mt-6 border border-white rounded-md py-2 text-sm text-white bg-blue-500 hover:bg-blue-600">
              <span className="absolute left-0 top-0 flex items-center justify-center h-full w-10 text-white">
                <i className="fab fa-facebook-f"></i>
              </span>
              <span>Login with Facebook</span>
            </button>

            <button onClick={signInWithGoogle} className="relative mt-6 border border-white rounded-md py-2 text-sm text-white bg-green-500 hover:bg-green-600">
              <span className="absolute left-0 top-0 flex items-center justify-center h-full w-10 text-white">
                <i className="fab fa-google"></i>
              </span>
              <span>Login with Google</span>
            </button>
            <div className="relative mt-10 h-px bg-gray-300">
              <div className="absolute left-0 top-0 flex justify-center w-full -mt-2">
                <span className="bg-white px-4 text-xs text-gray-500 uppercase">
                  Or Login With Email
                </span>
              </div>
            </div>

            <div className="mt-10">
              <Formik
                initialValues={formValues}
                validate={(values) => {
                  const errors: FormikErrors<LoginValues> = {};

                  if (!values.email) {
                    errors.email = "Email is required";
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                      values.email
                    )
                  ) {
                    errors.email = "Invalid email address";
                  }

                  if (!values.password) {
                    errors.password = "Password is required";
                  }

                  return errors;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  // @ts-ignore
                  await dispatch(postUserLogin(values));
                  setSubmitting(false);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit}>
                    {currentAuth?.error ? (
                      <div className="mb-4">
                        <label
                          htmlFor="name"
                          className=" text-xs sm:text-sm tracking-wide text-red-600 font-bold"
                        >
                          Login Error: {currentAuth?.error}
                        </label>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="flex flex-col mb-6">
                      <label
                        htmlFor="email"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        E-Mail Address:
                      </label>
                      <div className="relative">
                        <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                          <svg
                            className="h-6 w-6"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                          </svg>
                        </div>

                        <input
                          id="email"
                          type="email"
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="E-Mail Address"
                        />
                      </div>

                      <label
                        htmlFor="email"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-red-600"
                      >
                        {errors.email && touched.email && errors.email}
                      </label>
                    </div>

                    <div className="flex flex-col mb-6">
                      <label
                        htmlFor="password"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Password:
                      </label>
                      <div className="relative">
                        <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                          <span>
                            <svg
                              className="h-6 w-6"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                            </svg>
                          </span>
                        </div>

                        <input
                          id="password"
                          type="password"
                          name="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Password"
                        />

                        <label
                          htmlFor="password"
                          className="mb-1 text-xs sm:text-sm tracking-wide text-red-600"
                        >
                          {errors.password &&
                            touched.password &&
                            errors.password}
                        </label>
                      </div>
                    </div>

                    <div className="flex w-full">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-yellow-600 hover:bg-yellow-700 rounded py-2 w-full transition duration-150 ease-in"
                      >
                        <span className="mr-2 uppercase">
                          {isSubmitting ? "Please wait.." : "Login"}
                        </span>
                        <span>
                          <svg
                            className="h-6 w-6"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                        </span>
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
            <div className="flex justify-center items-center mt-6">
              <Link
                to={"/signup"}
                className="inline-flex items-center font-bold text-gray-500 hover:text-blue-700 text-xs text-center"
              >
                <span>
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" />
                  </svg>
                </span>
                <span className="ml-2">You don't have an account?</span>
              </Link>
            </div>
          </div>
        </div>

        <Footer />
      </Fragment>
    );
  }

  return <Fragment></Fragment>;
}


interface LoginValues {

  email: string;
  password: string;

}
