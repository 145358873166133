/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/** @jsxRuntime classic */
/** @jsx jsx */
import { Divider, List, Typography } from "antd";
import React, {
  Component,
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { browserName, browserVersion } from "react-device-detect";
import { css, jsx } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";

import { Base64 } from "js-base64";
import { Package } from "interfaces/package";
import { Service } from "interfaces/service";
import _ from "lodash";
import { buildPackageUrl } from "common/helper";
import config from "config.js";
import gamecoins from "controllers/components/svg/gamecoin";
import log from "common/log.js";
import { pushHistory } from "features/browse_histories/browseSlice";
import randomstring from "randomstring";
import { selectAuth } from "features/auh/authSlice";
import { serviceImage } from "./GameGrid";
import useDeviceQuery from "common/device";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

export function PackageItemDesktop(props: PackageItemProps) {
  let history = useHistory();
  const dispatch = useDispatch();
  const currentAuth = useSelector((state) => selectAuth(state));
  const { item, service } = props;

  let price = item?.user_price;
  if (currentAuth?.profile?.type === "partner") {
    price = item?.partner_price;
  }

  return (
    <div
      className="rounded-md border border-gray-300
                item hover:shadow-lg duration-500 bg-white"
      css={{ zIndex: 1, cursor: "pointer" }}
      onClick={() => {
        history.push(buildPackageUrl(service, item));
        dispatch(pushHistory({ service, item }));
      }}
    >
      <div className="p-4">
        <div className="flex flex-row">
          <div
            className="rounded-md mr-2"
            css={{
              width: 28,
              height: 28,
              backgroundImage: serviceImage(service),
              backgroundRepeat: "none",
              backgroundSize: "cover",
            }}
          ></div>
          <div className="flex flex-col ">
            <div css={{}}>{service?.name}</div>
          </div>
          <img className="icon" alt="icon" src={gamecoins}></img>
        </div>
        <div className="text-gray-600 pt-4" style={{ minHeight:82 }}>
          <strong>{item?.name}</strong><br/>
          {item?.unit_label}<br/>
          {item?.eta}
        </div>

        <div className="text-gray-600 pt-4 text-right">{item?.sold} Sold</div>
      </div>
      <div className="bg-gray-200 text-right p-4">
        from <strong>{price}</strong> USD
      </div>
    </div>
  );
}

export function PackageItemTablet(props: PackageItemProps) {
  let history = useHistory();
  const dispatch = useDispatch();
  const currentAuth = useSelector((state) => selectAuth(state));

  const { item, service } = props;
  let price = item?.user_price;
  if (currentAuth?.profile?.type === "partner") {
    price = item?.partner_price;
  }

  return (
    <div
      className="rounded-md border border-gray-300
                  item hover:shadow-lg duration-500 bg-white"
      css={{ zIndex: 1, cursor: "pointer" }}
      onClick={() => {
        history.push(buildPackageUrl(service, item));
        dispatch(pushHistory({ service, item }));
      }}
    >
      <div className="p-4">
        <div className="flex flex-row">
          <div
            className="rounded-md mr-2"
            css={{
              width: 28,
              height: 28,
              backgroundImage: serviceImage(service),
              backgroundRepeat: "none",
              backgroundSize: "cover",
            }}
          ></div>
          <div className="flex flex-col ">
            <div css={{}}>{service?.name}</div>
          </div>
          <img className="icon" alt="icon" src={gamecoins}></img>
        </div>

        <div className="text-gray-600 pt-4" style={{ minHeight:82 }}>
          <strong>{item?.name}</strong><br/>
          {item?.unit_label}<br/>
          {item?.eta}
        </div>


        <div className="text-gray-600 pt-4 text-right">{item?.sold} Sold</div>
      </div>
      <div className="bg-gray-200 text-right p-4">
        from <strong>{price}</strong> USD
      </div>
    </div>
  );
}

export function PackageItemMobile(props: PackageItemProps) {
  let history = useHistory();
  const dispatch = useDispatch();
  const currentAuth = useSelector((state) => selectAuth(state));

  const { item, service } = props;

  let price = item?.user_price;
  if (currentAuth?.profile?.type === "partner") {
    price = item?.partner_price;
  }

  return (
    <div
      className="rounded-md border border-gray-300 mb-4
                  item hover:shadow-lg duration-500 bg-white"
      css={{ zIndex: 1, cursor: "pointer" }}
      onClick={() => {
        history.push(buildPackageUrl(service, item));
        dispatch(pushHistory({ service, item }));
      }}
    >
      <div className="p-4">
        <div className="flex flex-row">
          <div className="flex flex-col ">
            <div css={{}}>{service?.name}</div>
          </div>
          <img className="icon" alt="icon" src={gamecoins}></img>
        </div>

        <div className="text-gray-600 pt-4" style={{ minHeight:82 }}>
          <strong>{item?.name}</strong><br/>
          {item?.unit_label}<br/>
          {item?.eta}
        </div>


        <div className="text-gray-600 pt-4 text-right">{item?.sold} Sold</div>
      </div>
      <div className="bg-gray-200 text-right p-4">
        from <strong>{price}</strong> USD
      </div>
    </div>
  );
}

export default function PackageGrid(props: any) {
  let history = useHistory();
  const dispatch = useDispatch();
  const {
    isDesktopOrLaptop,
    isTablet,
    isMobile,
    isIpadMini,
    isMobileAndIpadMini,
  } = useDeviceQuery();

  let { service } = props;

  if (isDesktopOrLaptop) {
    return (
      <div className="grid grid-cols-4 gap-10 package-grid pb-16">
        {props.data?.map((item: Package, index: number) => {
          return (
            <PackageItemDesktop
              service={service}
              item={item}
              key={"xxxx" + item.id}
            />
          );
        })}
      </div>
    );
  }

  if (isTablet) {
    return (
      <div
        className="grid grid-cols-2 gap-10 package-grid pb-16"
        css={{ padding: "0 20px" }}
      >
        {props.data?.map((item: Package, index: number) => {
          return (
            <PackageItemTablet
              service={service}
              item={item}
              key={"xxxx" + item.id}
            />
          );
        })}
      </div>
    );
  }

  if (isMobile) {
    return (
      <div
        className="flex flex-col package-grid pb-16"
        css={{ padding: "0 20px" }}
      >
        {props.data?.map((item: Package, index: number) => {
          return (
            <PackageItemMobile
              service={service}
              item={item}
              key={"xxxx" + item.id}
            />
          );
        })}
      </div>
    );
  }

  return <React.Fragment></React.Fragment>;
}

export interface PackageItemProps {
  service: Service,
  item: Package
}
