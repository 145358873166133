/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/** @jsxRuntime classic */
/** @jsx jsx */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Affix, Button } from "antd";
import { Breadcrumb, Layout, Menu } from "antd";
import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { css, jsx } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";

import { Divider } from "antd";
import _ from "lodash";
import config from "config.js";
import log from "common/log.js";
import logo from "assets/images/logo.png";
import { selectConfig } from "features/config/configSlice";
import useDeviceQuery from "common/device";
import { useMediaQuery } from "react-responsive";

const axios = require("axios");
const { SubMenu } = Menu;
const { Header, Content, Footer } = Layout;


export default function LayoutFooter() {
  const {
    isDesktopOrLaptop,
    isTablet,
    isMobile,
    isIpadMini,
    isMobileAndIpadMini,
  } = useDeviceQuery();

  const siteConfig = useSelector((state) => selectConfig(state));


  return (
    <React.Fragment>
      <Divider css={{ margin: "12px 0" }} />
      <div
        className="text-center font-semibold"
        onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }}
      >
        <i className="ri-arrow-up-s-line"></i> Back to top
      </div>

      <Divider css={{ margin: "12px 0 24px 0" }} />
      <div className="container mx-auto mt-8 home-trending-games pb-2">
        <div className="bm-columns">

          {siteConfig?.chat_accounts ? JSON.parse(siteConfig?.chat_accounts).map((col: any) => {

            return <div className="bm-column">
              <div className=" p-3">
                <div className="uppercase text-gray-600 mb-6">{col?.title}</div>
                <div className="flex flex-col ">
                  {
                    col?.links?.map((link: any) => {
                      return <div>
                        <a href={link.url} className="text-black">
                          {link.title}
                        </a>
                      </div>
                    })
                  }
                </div>
              </div>
            </div>


          }) : ""}



        </div>
      </div>
      <Divider css={{ margin: "12px 0 24px 0" }} />
      <div className="container mx-auto mt-8 home-trending-games pb-2">
        <div className="bm-columns">

          {siteConfig?.footer_link_json ? JSON.parse(siteConfig?.footer_link_json).map((col: any) => {

            return <div className="bm-column">
              <div className=" p-3">
                <div className="uppercase text-gray-600 mb-6">{col?.title}</div>
                <div className="flex flex-col ">
                  {
                    col?.links?.map((link: any) => {
                      return <div>
                        <a href={link.url} className="text-black">
                          {link.title}
                        </a>
                      </div>
                    })
                  }
                </div>
              </div>
            </div>


          }) : ""}



        </div>
      </div>
      <Divider />
      <div className="container mx-auto mt-8 home-trending-games pb-2">
        <div className="p-3">
          <div className="text-gray-600">
            {siteConfig?.copyrights_text}
          </div>

          {isDesktopOrLaptop ? <div>
            <span className="text-gray-600 mr-8">{siteConfig?.copyrights_domain}</span>{" "}
            <a href={"/policy"} className="text-black">
              Privacy Policy
            </a>{" "}
            •{" "}
            <a href={"/terms"} className="text-black">
              Terms of Service
            </a>{" "}
            {/* •{" "}
            <a href={"/"} className="text-black">
              Affiliate Agreement
            </a> */}
          </div> : ""}

          {isTablet ? <div>
            <span className="text-gray-600 mr-8">{siteConfig?.copyrights_domain}</span>{" "}
            <a href={"/policy"} className="text-black">
              Privacy Policy
            </a>{" "}
            •{" "}
            <a href={"/terms"} className="text-black">
              Terms of Service
            </a>{" "}
            {/* •{" "}
            <a href={"/"} className="text-black">
              Affiliate Agreement
            </a> */}
          </div> : ""}

          {isMobile ? <div>
            <span className="text-gray-600 mr-8">{siteConfig?.copyrights_domain}</span><br /><br />
            <a href={"/policy"} className="text-black">
              Privacy Policy
            </a><br />
            <a href={"/terms"} className="text-black">
              Terms of Service
            </a>
            {/* <br/>
            <a href={"/"} className="text-black">
              Affiliate Agreement
            </a> */}
          </div> : ""}

        </div>
      </div>
    </React.Fragment>
  );
}
