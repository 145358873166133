/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  jsx-a11y/anchor-is-valid */
/** @jsxRuntime classic */
/** @jsx jsx */
import { Divider, List, Typography } from "antd";
import {
  PackageItemDesktop,
  PackageItemMobile,
  PackageItemTablet,
} from "controllers/components/PackageGrid";
import React, {
  Component,
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { browserName, browserVersion } from "react-device-detect";
import {
  clearHistory,
  pushHistory,
} from "features/browse_histories/browseSlice";
import { css, jsx } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";

import Axios from "axios";
import { Base64 } from "js-base64";
import { HistoryItem } from "interfaces/browse_history";
import { Link } from "react-router-dom";
import TrendingGamesBox from "./HomeTrendingGames/TrendingGamesBox";
import _ from "lodash";
import { buildServiceUrl } from "common/helper";
import config from "config.js";
import { getCategoriesAPI } from "api/api";
import log from "common/log.js";
import randomstring from "randomstring";
import { selectBrowseHistories } from "features/browse_histories/browseSlice";
import { selectCategories } from "features/categories/categorySlice";
import { selectConfig } from "features/config/configSlice";
import useDeviceQuery from "common/device";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

export default function HomeBrowseHistories() {
  const { isDesktopOrLaptop, isTablet, isMobile, isIpadMini } =
    useDeviceQuery();

  const histories: HistoryItem[] = useSelector((state) => selectBrowseHistories(state));

  if (histories.length > 0) {
    if (isDesktopOrLaptop) {
      return (
        <Fragment>
          <div className="bg-gray-100">
            <div className="container mx-auto mt-8 pt-8">
              <div className="text-3xl text-center mb-8">Browsing History</div>
              <div className="grid grid-cols-3 gap-10 package-grid pb-16">
                {histories?.map((item: HistoryItem, index: number) => {
                  return (
                    <PackageItemDesktop
                      key={"xxxx" + item.item.id}
                      service={item.service}
                      item={item.item}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </Fragment>
      );
    }

    if (isTablet) {
      return (
        <Fragment>
          <div className="bg-gray-100">
            <div className="container mx-auto mt-8 pt-8">
              <div className="text-3xl text-center mb-8">Browsing History</div>
              <div
                className="grid grid-cols-2 gap-10 package-grid pb-16"
                css={{ padding: "0 20px" }}
              >
                {histories?.map((item: HistoryItem, index: number) => {
                  return (
                    <PackageItemTablet
                      key={"xxxx" + item.item.id}
                      service={item.service}
                      item={item.item}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </Fragment>
      );
    }

    if (isMobile) {
      return (
        <Fragment>
          <div className="bg-gray-100">
            <div className="container mx-auto mt-8 pt-8">
              <div className="text-3xl text-center mb-8">Browsing History</div>
              <div
                className="flex flex-col package-grid pb-16"
                css={{ padding: "0 20px" }}
              >
                {histories?.map((item: HistoryItem, index: number) => {
                  return (
                    <PackageItemMobile
                      key={"xxxx" + item.item.id}
                      service={item.service}
                      item={item.item}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
  }

  return <Fragment></Fragment>;
}
