import Axios from "axios";
import config from "config.js";

export function getCategoriesAPI() {
  return Axios.get(config.api.get_categories);
}

export function getTrendingAPI(data) {
  return Axios.get(config.api.get_trendings, data);
}

export function getServicesAPI(data) {
  return Axios.get(config.api.get_services, data);
}

export function getServiceAPI(data) {
  return Axios.get(config.api.get_service, data);
}

export function getPackageAPI(data) {
  return Axios.get(config.api.get_package, data);
}

export function getPackagesAPI(data) {
  return Axios.get(config.api.get_packages, data);
}

export function getConfigAPI() {
  return Axios.get(config.api.get_config);
}

export function postUserSignupAPI(data) {
  return Axios.post(config.api.post_register, data);
}

export function postUserLoginAPI(data) {
  return Axios.post(config.api.post_login, data);
}

export function getUserProfileAPI(data) {
  return Axios.get(config.api.get_profile, data);
}

export function postBuyPakcage(data, options) {
  return Axios.post(config.api.post_buy, data, options);
}

export function getUserTransactions(data) {
  return Axios.get(config.api.get_transactions, data);
}

export function getUserTransaction(data) {
  return Axios.get(config.api.get_transaction, data);
}

export function postVerifyToken(data) {
  return Axios.post(config.api.post_verify_token, data);
}
