import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getCategoriesAPI } from "api/api";
import log from "common/log.js";

const initialState = {
  data: [],
  status: "idle",
  error: null,
};

export const getCategories = createAsyncThunk("categories/get", async () => {
  return getCategoriesAPI()
    .then((repsonse) => {
      return repsonse.data;
    })
    .catch((error) => {
      log(error);
    });
});

const categorySlice = createSlice({
  name: "categories",
  initialState,
  reducers: {},
  extraReducers: {
    [getCategories.pending]: (state, action) => {
      state.status = "loading";
    },
    [getCategories.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    },
  },
});

export default categorySlice.reducer;
export const selectCategories = (state) => state.categories.data;
