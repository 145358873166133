/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/** @jsxRuntime classic */
/** @jsx jsx */

import {
  Affix,
  Breadcrumb,
  Button,
  Card,
  Col,
  Input,
  Layout,
  Menu,
  Row,
  Select,
} from "antd";
import { Divider, List, Typography } from "antd";
import React, {
  Component,
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { browserName, browserVersion } from "react-device-detect";
import { css, jsx } from "@emotion/react";
import { getPackageAPI, getServiceAPI } from "api/api";
import { useHistory, useParams } from "react-router-dom";

import Axios from "axios";
import { Base64 } from "js-base64";
import BreadCumb from "./BreadCumb";
import Footer from "controllers/components/Footer";
import Header from "controllers/components/Header";
import { InputNumber } from "antd";
import { Link } from "react-router-dom";
import { Package } from "interfaces/package";
import PackageBuy from "./PackageBuy";
import PackageInfo from "./PackageInfo";
import { Service } from "interfaces/service";
import _ from "lodash";
import config from "config.js";
import log from "common/log.js";
import randomstring from "randomstring";
import { scrollTop } from "common/helper";
import useDeviceQuery from "common/device";
import { useMediaQuery } from "react-responsive";

interface ParamTypes {
  serviceId?: string | undefined; serviceSlug?: string | undefined; packageId?: string | undefined
}

export default function PackageDetail(props: any) {
  const {
    isDesktopOrLaptop,
    isTablet,
    isMobile,
    isIpadMini,
    isMobileAndIpadMini,
  } = useDeviceQuery();

  let { serviceId, serviceSlug, packageId } = useParams<ParamTypes>();

  const [service, setService] = useState<Service | null>(null);
  const [servicePackage, setServicePackage] = useState<Package | null>(null);

  useEffect(() => {
    scrollTop();
  }, [])

  useEffect(() => {
    /////
    getServiceAPI({
      params: {
        serviceId: serviceId,
      },
    })
      .then((response) => {
        setService(response.data);
      })
      .catch((error) => {
        log(error);
      });

    /////
    getPackageAPI({
      params: {
        packageId: packageId,
      },
    })
      .then((response) => {
        setServicePackage(response.data);
      })
      .catch((error) => {
        log(error);
      });
  }, [packageId, serviceId]);

  if (service && servicePackage) {
    return (
      <Fragment>
        <Header showBack={true} />
        <div className="bg-gray-200">
          <div
            className="container mx-auto pt-8 pb-16"
            css={{ minHeight: 600 }}
          >
            {isDesktopOrLaptop ? (
              <BreadCumb service={service} servicePackage={servicePackage} />
            ) : (
              ""
            )}

            {isTablet ? (
              <div className="mt-16" css={{ padding: "0 20px" }}>
                <BreadCumb service={service} servicePackage={servicePackage} />
              </div>
            ) : (
              ""
            )}

            {isMobile ? (
              <div className="mt-16" css={{ padding: "0 20px" }}>
                <BreadCumb service={service} servicePackage={servicePackage} />
              </div>
            ) : (
              ""
            )}

            {isMobile || isIpadMini ? (
              <div className="bm-columns">
                <div className="bm-column">
                  <PackageBuy
                    service={service}
                    servicePackage={servicePackage}
                  />
                </div>
                <div className="bm-column bm-is-two-thirds">
                  <PackageInfo
                    service={service}
                    servicePackage={servicePackage}
                  />
                </div>
              </div>
            ) : (
              <div className="bm-columns">
                <div className="bm-column bm-is-two-thirds">
                  <PackageInfo
                    service={service}
                    servicePackage={servicePackage}
                  />
                </div>
                <div className="bm-column">
                  <PackageBuy
                    service={service}
                    servicePackage={servicePackage}
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <Footer />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Header logined />
      <div className="bg-gray-200">
        <div
          className="container mx-auto pt-8 pb-16"
          css={{ minHeight: 600 }}
        ></div>
      </div>

      <Footer />
    </Fragment>
  );
}
