import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth";

import { getAnalytics } from "firebase/analytics";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { postVerifyToken } from "api/api";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBPdLup9ypn3SrJJv26k5fPuvWsZwrt9PA",
  authDomain: "boxgem-63434.firebaseapp.com",
  projectId: "boxgem-63434",
  storageBucket: "boxgem-63434.appspot.com",
  messagingSenderId: "340912495704",
  appId: "1:340912495704:web:94e668366cfc1836a4fe2d",
  measurementId: "G-8D3739B7EV",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    console.log(user);
    const formData = new FormData();
    formData.append("token", user.accessToken);
    let response1 = await postVerifyToken(formData);
    if (response1.data.token) {
      localStorage.setItem("bearer", response1.data.token);
      window.location.reload();
    }
    // const q = query(collection(db, "users"), where("uid", "==", user.uid));
    // const docs = await getDocs(q);
    // if (docs.docs.length === 0) {
    //   await addDoc(collection(db, "users"), {
    //     uid: user.uid,
    //     name: user.displayName,
    //     authProvider: "google",
    //     email: user.email,
    //   });
    // }
  } catch (error) {
    console.error(error);
    // alert(err.message);

    // An error happened.
    if (error.code === "auth/account-exists-with-different-credential") {
      const res = await signInWithPopup(auth, facebookProvider);
      const user = res.user;
      console.log(user);
      const formData = new FormData();
      formData.append("token", user.accessToken);
      let response1 = await postVerifyToken(formData);
      if (response1.data.token) {
        localStorage.setItem("bearer", response1.data.token);
        window.location.reload();
      }
    }
  }
};

const signInWithFacebook = async () => {
  try {
    const res = await signInWithPopup(auth, facebookProvider);
    const user = res.user;
    console.log(user);
    const formData = new FormData();
    formData.append("token", user.accessToken);
    let response1 = await postVerifyToken(formData);
    if (response1.data.token) {
      localStorage.setItem("bearer", response1.data.token);
      window.location.reload();
    }
    // const q = query(collection(db, "users"), where("uid", "==", user.uid));
    // const docs = await getDocs(q);
    // if (docs.docs.length === 0) {
    //   await addDoc(collection(db, "users"), {
    //     uid: user.uid,
    //     name: user.displayName,
    //     authProvider: "google",
    //     email: user.email,
    //   });
    // }
  } catch (error) {
    console.error(error);

    // // An error happened.
    // if (error.code === "auth/account-exists-with-different-credential") {
    //   const res = await signInWithPopup(auth, googleProvider);
    //   const user = res.user;
    //   console.log(user);
    //   const formData = new FormData();
    //   formData.append("token", user.accessToken);
    //   let response1 = await postVerifyToken(formData);
    //   if (response1.data.token) {
    //     localStorage.setItem("bearer", response1.data.token);
    //     window.location.reload();
    //   }
    // }
    // alert(err.message);
  }
};

export { auth, signInWithGoogle, signInWithFacebook };
