import authReducer from "features/auh/authSlice";
import browseReducer from "features/browse_histories/browseSlice";
import cartReducer from "features/cart/cartSlice";
import categoryReducer from "features/categories/categorySlice";
import configReducer from "features/config/configSlice";
import { configureStore } from "@reduxjs/toolkit";
export default configureStore({
  reducer: {
    auth: authReducer,
    categories: categoryReducer,
    config: configReducer,
    cart: cartReducer,
    browse: browseReducer,
  },
});
