/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  jsx-a11y/anchor-is-valid */

import { useEffect, useState } from "react";

import { useMediaQuery } from "react-responsive";

export default function useDeviceQuery() {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });
  const isTablet = useMediaQuery({ minWidth: 751, maxWidth: 1223 });
  const isMobile = useMediaQuery({ maxWidth: 750 });
  const isIpadMini = useMediaQuery({ minWidth: 751, maxWidth: 768 });
  const isTabletWithoutIpadMini = useMediaQuery({ minWidth: 769, maxWidth: 1223 });
  const isMobileAndIpadMini = useMediaQuery({ maxWidth: 768 });
  const [devices, setDevices] = useState({
    isDesktopOrLaptop,
    isTablet,
    isMobile,
    isIpadMini,
    isMobileAndIpadMini,isTabletWithoutIpadMini
  });
  return devices;
}
