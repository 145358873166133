/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  jsx-a11y/anchor-is-valid */
/** @jsxRuntime classic */
/** @jsx jsx */
import {
  Affix,
  Breadcrumb,
  Button,
  Card,
  Col,
  Input,
  Layout,
  Menu,
  Row,
  Select,
} from "antd";
import { Divider, List, Typography } from "antd";
import { Package, PackageDataProps } from "interfaces/package";
import React, {
  Component,
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { browserName, browserVersion } from "react-device-detect";
import { css, jsx } from "@emotion/react";
import { getCategoriesAPI, getTrendingAPI } from "api/api";

import { Base64 } from "js-base64";
import { Category } from "interfaces/category";
import { InputNumber } from "antd";
import { Link } from "react-router-dom";
import _ from "lodash";
import { buildServiceUrl } from "common/helper";
import config from "config.js";
import log from "common/log.js";
import randomstring from "randomstring";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

export default function BreadCumb(props: PackageDataProps) {
  let { service, servicePackage } = props;

  const [category, setCategory] = useState<Category | null>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    getCategoriesAPI()
      .then((repsonse) => {
        let data = repsonse.data;
        data.forEach((element: Category) => {
          if (service.category_id === element.id) {
            setCategory(element);
          }
        });
      })
      .catch((error) => {
        log(error);
      });
  }, [service.category_id]);

  return (
    <nav
      className="bm-breadcrumb bm-has-arrow-separator"
      aria-label="breadcrumbs"
    >
      <ul>
        <li>
          <Link to={"/"}>Home</Link>
        </li>
        {category ? (
          <li>
            <Link to={`/trending/${category?.id}`}>{category?.name}</Link>
          </li>
        ) : (
          ""
        )}

        <li>
          <Link to={buildServiceUrl(service)} aria-current="page">
            {service.name}
          </Link>
        </li>
        <li className="bm-is-active">
          <a href="#" aria-current="page">
            {servicePackage.name}
          </a>
        </li>
      </ul>
    </nav>
  );
}
