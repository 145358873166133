/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/** @jsxRuntime classic */
/** @jsx jsx */
//import styles 👇
import "react-modern-drawer/dist/index.css";

import {
  Affix,
  Breadcrumb,
  Button,
  Card,
  Col,
  Input,
  Layout,
  Menu,
  Row,
  Select,
} from "antd";
import React, {
  Component,
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { ServiceItem, Services } from "interfaces/services";
import { css, jsx } from "@emotion/react";
import {
  getCategoriesAPI,
  getServicesAPI,
  getTrendingAPI,
  getUserTransactions,
} from "api/api";
import { useDispatch, useSelector } from "react-redux";

import Avatar from "react-avatar";
import { Divider } from "antd";
import Drawer from "react-modern-drawer";
import { Link } from "react-router-dom";
import _ from "lodash";
import { buildServiceUrl } from "common/helper";
import config from "config.js";
import log from "common/log.js";
import logo from "assets/images/logo.png";
import { selectAuth } from "features/auh/authSlice";
import { selectCategories } from "features/categories/categorySlice";
import useDeviceQuery from "common/device";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { userLogout } from "features/auh/authSlice";

const axios = require("axios");
const { SubMenu } = Menu;
const { Header, Content, Footer } = Layout;
const { Option } = Select;

function LogoDesktop() {
  let history = useHistory();
  return (
    <div
      className="logo"
      css={{
        width: 150,
        paddingTop: 10,
        float: "left",
        marginRight: 50,
        cursor: "pointer",
      }}
      onClick={() => {
        history.push("/");
      }}
    >
      <img src={logo} width={150} alt={"Logo"} />
    </div>
  );
}

function LogoCenter() {
  let history = useHistory();
  return (
    <div
      className="logo"
      css={{
        width: 150,
        paddingTop: 10,
        margin: "auto",
        cursor: "pointer",
      }}
      onClick={() => {
        history.push("/");
      }}
    >
      <img src={logo} width={150} alt={"Logo"} />
    </div>
  );
}

function TopSearchBar() {
  let history = useHistory();
  const allCategories = useSelector((state) => selectCategories(state));
  const searchBoxRef = useRef<HTMLDivElement>(null);
  const [searchResults, setSearchResults] = useState<ServiceItem[] | null>(null);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectCategoryId, setSelectCategoryId] = useState("all");

  const handleSearchInputChange = (e: any) => {
    let val = e.target.value;

    if (val.length > 0) {
      setSearchKeyword(val);

      let params: any = {
        search: val,
        limit: 10,
      };
      if (selectCategoryId !== "all") {
        params["categoryId"] = selectCategoryId;
      }
      getServicesAPI({
        params: params,
      })
        .then((repsonse) => {
          let services: Services = repsonse.data;
          let data = services.data;
          if (data.length > 0) {
            setSearchResults(data);
          } else {
            setSearchResults(null);
          }
        })
        .catch((error) => {
          log(error);
        });
    } else {
      setSearchKeyword("");
    }
  };

  return (
    <div className="top-search mt-4 flex flex-row" ref={searchBoxRef}>
      <Select
        defaultValue="all"
        size={"large"}
        css={{
          width: 180,
          backgroundColor: "#f5c814",
          height: 40,
          color: "#000",
        }}
        bordered={false}
        onChange={(val) => {
          setSelectCategoryId(val);
          log(val);
        }}
      >
        <Option value="all">All Services</Option>
        {allCategories.map((item: any) => {
          return (
            <Option value={item.id} key={`option-${item.id}`}>
              {item.name}
            </Option>
          );
        })}
      </Select>
      <Input
        allowClear
        placeholder="Search for game"
        css={{ width: 288, height: 40 }}
        bordered={false}
        onChange={handleSearchInputChange}
      />
      {searchKeyword !== "" ? (
        <div
          className="rounded round-lg shadow shadow-lg transition-all duration-100"
          css={{
            minHeight: 100,
            width: 308,
            position: "absolute",
            top: 60,
            left: searchBoxRef?.current
              ? searchBoxRef.current.getBoundingClientRect().left + 180
              : 0,
            background: "#fff",
            padding: 20,
          }}
        >
          {searchResults?.map((item: any) => {
            return (
              <div
                className="cursor-pointer"
                css={{ lineHeight: "20px", marginBottom: 10 }}
                onClick={() => {
                  window.location.href = buildServiceUrl(item);
                }}
              >
                <div className="text-gray-700 hover:text-blue-700 font-bold">
                  {item.name}
                </div>

                <div className="text-gray-500">
                  {
                    allCategories.find((cat: any) => cat.id === item.category_id)
                      ?.name
                  }
                </div>
                <Divider css={{ margin: "12px 0" }} />
              </div>
            );
          })}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

function UserAvatar(props: any) {
  const currentAuth = useSelector((state) => selectAuth(state));
  const [showMenu, setShowMenu] = useState(false);
  const dispatch = useDispatch();
  return (
    <Fragment>

      <div className="text-white mt-4">
      <Link to="/user/transactions" className="bm-button bm-is-small bm-is-warning mr-2 mt-2">
            <i className="ri-shopping-bag-line"></i>  Purchased Orders
            </Link>

        <Avatar
          className="font-bold"
          name={currentAuth?.profile?.email}
          round={true}
          size="42"
          onClick={() => setShowMenu(!showMenu)}
        />
      </div>
      {showMenu ? (
        <div
          className="rounded round-lg shadow shadow-lg transition-all duration-100"
          css={{
            minHeight: 100,
            width: 200,
            position: "absolute",
            top: 70,
            right: 20,
            background: "#fff",
            padding: 20,
            textAlign: "left",
          }}
        >
          <div className="font-bold mb-4" css={{
            color:"#755309"
           }}><i className="ri-user-line"></i> {currentAuth?.profile?.email}</div>

          <div className="mb-4">
            <Link to="/user/transactions" css={{ color: "#333" }}>
            <i className="ri-shopping-bag-line"></i> Purchased Orders
            </Link>
          </div>

          <div
            className="mb-4 cursor-pointer"
            onClick={() => {
              dispatch(userLogout());
            }}
          >
            <Link to="/" css={{ color: "#333" }}>
              <i className="ri-logout-box-r-line"></i> Logout
            </Link>
          </div>
        </div>
      ) : (
        <Fragment></Fragment>
      )}
    </Fragment>
  );
}

export default function LayoutHeader(props: any) {
  let history = useHistory();
  const dispatch = useDispatch();
  const {
    isDesktopOrLaptop,
    isTablet,
    isMobile,
    isIpadMini,
    isMobileAndIpadMini,
  } = useDeviceQuery();

  const currentAuth = useSelector((state) => selectAuth(state));
  const toggleDrawer = () => {
    setIsDrawerOpen((prevState) => !prevState);
  };

  const [activeHeaderShowClass, setActiveHeaderShowClass] =
    useState("shadow-sm");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { showBack, pageTitle } = props;

  useEffect(() => {
    window.addEventListener("scroll", () => {
      let _activeHeaderShowClass = "shadow-md";

      if (window.scrollY === 0) {
        _activeHeaderShowClass = "shadow-sm";
      }
      setActiveHeaderShowClass(_activeHeaderShowClass);
    });
  }, []);


  let showAuthButtons =
    !currentAuth.profile &&
    currentAuth.status === "succeeded" &&
    !props.isAuthPage;

  let DesktopHeader = () => {
    return (
      <React.Fragment>
        <Header
          className={`${activeHeaderShowClass} transition duration-150 ease-in-out desktop-header `}
          css={{
            position: "fixed",
            top: 0,
            zIndex: 999,
            width: "100%",
            backgroundColor: "#333",
          }}
        >
          <div className="flex flex-row justify-between">
            <LogoDesktop />
            <TopSearchBar />
            <div>
              {showAuthButtons ? (
                <Fragment>
                  <Link
                    to={"/signup"}
                    className="bm-button bm-is-white mr-2 mt-4"
                  >
                    Sign up
                  </Link>
                  <Link to={"/login"} className="bm-button bm-is-warning mt-4">
                    Login
                  </Link>
                </Fragment>
              ) : (
                <Fragment>
                  {currentAuth?.profile?.email ? (
                    <div
                      className="flex flex-col text-right"
                      css={{ lineHeight: "20px" }}
                    >
                      <UserAvatar />

                    </div>
                  ) : (
                    ""
                  )}
                </Fragment>
              )}
            </div>
          </div>
        </Header>

        <div
          css={{
            marginTop: 72,
            display: "table",
            height: 1,
            width: "100%",
            background: "#333",
          }}
        ></div>
      </React.Fragment>
    );
  };

  let TabletHeader = () => {
    return (
      <React.Fragment>
        <Header
          className={`${activeHeaderShowClass} transition duration-150 ease-in-out desktop-header `}
          css={{
            transform: "translate3d(0,0,0)",
            position: "fixed",
            top: 0,
            zIndex: 999,
            width: "100%",
            backgroundColor: "#333",
          }}
        >
          <div className="flex flex-row justify-between">
            <LogoDesktop />

            {showAuthButtons ? (
              <div>
                <Link
                  to={"/signup"}
                  className="bm-button bm-is-white mr-2 mt-4"
                >
                  Sign up
                </Link>
                <Link to={"/login"} className="bm-button bm-is-warning mt-4">
                  Login
                </Link>
              </div>
            ) : (
              <Fragment>
                {currentAuth?.profile?.email ? (
                  <div
                    className="flex flex-col text-right"
                    css={{ lineHeight: "20px" }}
                  >
                    <div className="text-white mt-4">
                      <Avatar
                        name={currentAuth?.profile?.email}
                        round={true}
                        size="42"
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </Fragment>
            )}
          </div>
        </Header>
      </React.Fragment>
    );
  };

  let MobileHeader = () => {
    return (
      <React.Fragment>
        <Header
          className={`${activeHeaderShowClass} transition duration-150 ease-in-out desktop-header`}
          css={{
            transform: "translate3d(0,0,0)",
            position: "fixed",
            top: 0,
            zIndex: 999,
            width: "100%",
            backgroundColor: "#333",
          }}
        >
          <div
            className={`flex flex-row ${!showBack ? "justify-between" : ""}`}
          >
            {showBack ? (
              <i
                className="ri-arrow-left-s-line"
                css={{
                  left: 10,
                  position: "absolute",
                  color: "#fff",
                  fontSize: 36,
                }}
                onClick={() => {
                  history.goBack();
                }}
              ></i>
            ) : (
              ""
            )}
            {pageTitle ? (
              <div className="text-white text-xl">{pageTitle}</div>
            ) : (
              <LogoCenter />
            )}

            {currentAuth?.profile?.email ? (
              <Fragment>
                <button
                  className="bm-button mt-4 bm-is-black"
                  onClick={toggleDrawer}
                  css={{ right: 10, position: "absolute" }}
                >
                  <i className="ri-menu-line"></i>
                </button>
                <Drawer
                  open={isDrawerOpen}
                  onClose={toggleDrawer}
                  direction="right"
                  css={{ backgroundColor: "#333", padding: 20 }}
                >
                  <div
                    className="flex flex-col text-right"
                    css={{ lineHeight: "20px" }}
                  >
                    <div className="text-white mt-4">
                      Hi, <strong>{currentAuth?.profile?.email}</strong>
                    </div>

                    <div
                      className="bm-button bm-is-gray cursor-pointer mt-10"
                      onClick={() => {
                        dispatch(userLogout());
                      }}
                    >
                      Log Out
                    </div>
                  </div>
                </Drawer>
              </Fragment>
            ) : (
              ""
            )}
          </div>
        </Header>

        {showAuthButtons ? (
          <Affix offsetBottom={0}>
            <Header
              className={`transition duration-150 ease-in-out desktop-header `}
              css={{
                transform: "translate3d(0,0,0)",
                position: "fixed",
                bottom: 0,
                zIndex: 999,
                width: "100%",
                backgroundColor: "#fff",
                height: "66px !important",
                lineHeight: "66px !important",
                padding: "0 20px !important",
                border: "1px solid #ccc",
              }}
            >
              <div className="flex flex-row justify-between">
                <Link
                  to={"/signup"}
                  className="bm-button bm-is-gray bm-is-fullwidth mt-3 mr-4"
                >
                  Sign up
                </Link>
                <Link
                  to={"/login"}
                  className="bm-button bm-is-warning bm-is-fullwidth mt-3"
                >
                  Login
                </Link>
              </div>
            </Header>
          </Affix>
        ) : (
          <Fragment></Fragment>
        )}
      </React.Fragment>
    );
  };

  if (isDesktopOrLaptop) return <DesktopHeader />
  if (isTablet) return <TabletHeader />
  if (isMobile) return <MobileHeader />

  return (
    <React.Fragment>

    </React.Fragment>
  );
}
