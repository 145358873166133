/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/** @jsxRuntime classic */
/** @jsx jsx */
import { Divider, List, Typography } from "antd";
import React, {
  Component,
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { browserName, browserVersion } from "react-device-detect";
import { css, jsx } from "@emotion/react";

import Axios from "axios";
import { Base64 } from "js-base64";
import { Link } from "react-router-dom";
import { Service } from "interfaces/service";
import { Services } from "interfaces/services";
import _ from "lodash";
import { buildServiceUrl } from "common/helper";
import config from "config.js";
import { getTrendingAPI } from "api/api";
import log from "common/log.js";
import randomstring from "randomstring";
import useDeviceQuery from "common/device";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

export default function TrendingGamesBox(props: any) {
  let history = useHistory();
  const { isDesktopOrLaptop, isTablet, isMobile, isIpadMini } =
    useDeviceQuery();

  const [services, setServices] = useState(null);
  let categoryURL = `/trending/${props.data?.category?.id}`

  useEffect(() => {
    getTrendingAPI({
      params: {
        categoryId: props.data?.category?.id,
        limit: 5,
      },
    })
      .then((repsonse) => {
        setServices(repsonse.data);
      })
      .catch((error) => {
        log(error);
      });
  }, [props.data?.category?.id]);

  if (services) {
    if (isDesktopOrLaptop) {
      return (
        <div
          className={`shadow-sm rounded-lg  overflow-hidden border border-gray-300`}
        >
          <div className={`box-header p-6 ${props.headerClassName}`}>
            <div>SERVICE</div>
            <div className="text-2xl">{props.data?.service}</div>
            <div className="icon-container">
              <div className="icon"></div>
            </div>
          </div>
          <div className="box-content">
            <List
              size="large"
              header={<React.Fragment></React.Fragment>}
              footer={
                <div className="text-right text-yellow-700 cursor-pointer">
                  <Link className="text-yellow-700" to={categoryURL}>
                    Discover All
                  </Link>
                </div>
              }
              bordered
              dataSource={services}
              renderItem={(item) => <GameListItem item={item} />}
            />
          </div>
        </div>
      );
    }

    if (isTablet) {
      return (
        <div
          className={`${!isIpadMini ? "shadow-sm rounded-lg" : ""
            } trending-box-tablet overflow-hidden border border-gray-300`}
        >
          <div className={`box-header p-6 ${props.headerClassName}`}>
            <div>SERVICE</div>
            <div className="text-2xl">{props.data?.service}</div>
            <div className="icon-container">
              <div className="icon"></div>
            </div>
          </div>
          <div className="box-content">
            <List
              size="large"
              header={<React.Fragment></React.Fragment>}
              footer={
                <div className="text-right text-yellow-700 cursor-pointer">
                  <Link className="text-yellow-700" to={categoryURL}>
                    Discover All
                  </Link>
                </div>
              }
              bordered={!isIpadMini ? true : false}
              dataSource={services}
              renderItem={(item) => <GameListItem item={item} />}
            />
          </div>
        </div>
      );
    }

    if (isMobile) {
      return (
        <div className={`overflow-hidden`}>
          <div className={`box-header p-6 ${props.headerClassName}`}>
            <div>SERVICE</div>
            <div className="text-2xl">{props.data?.service}</div>
            <div className="icon-container">
              <div className="icon"></div>
            </div>
          </div>
          <div className="box-content">
            <List
              size="large"
              header={<React.Fragment></React.Fragment>}
              footer={
                <div className="text-right text-yellow-700 cursor-pointer">
                  <Link className="text-yellow-700" to={categoryURL}>
                    Discover All
                  </Link>
                </div>
              }
              bordered
              dataSource={services}
              renderItem={(item) => <GameListItem item={item} />}
            />
          </div>
        </div>
      );
    }
  }

  return <Fragment></Fragment>;
}

function GameListItem(props: any) {
  let history = useHistory();
  return (
    <List.Item
      className="flex flex-row justify-between cursor-pointer"
      onClick={() => {
        history.push(buildServiceUrl(props.item));
      }}
    >
      <strong>{props.item.name}</strong>
      <span className="text-gray-500">{props.item.sold_packages} Sold</span>
    </List.Item>
  );
}
