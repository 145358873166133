/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import log from "common/log.js";

const initialState = {
  data: null,
  status: "idle",
  error: null,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {},
  extraReducers: {},
});

export default cartSlice.reducer;
export const selectConfig = (state) => state.config.data;
