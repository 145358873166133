import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getConfigAPI } from "api/api";
import log from "common/log.js";

const initialState = {
  data: null,
  status: "idle",
  error: null,
};

export const getConfig = createAsyncThunk("config/get", async () => {
  return getConfigAPI()
    .then((repsonse) => {
      return repsonse.data;
    })
    .catch((error) => {
      log(error);
    });
});

const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {},
  extraReducers: {
    [getConfig.pending]: (state, action) => {
      state.status = "loading";
    },
    [getConfig.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    },
  },
});

export default configSlice.reducer;
export const selectConfig = (state) => state.config.data;
