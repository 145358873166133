/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/** @jsxRuntime classic */
/** @jsx jsx */

import {
  Affix,
  Breadcrumb,
  Button,
  Card,
  Col,
  Input,
  Layout,
  Menu,
  Row,
  Select,
} from "antd";
import { Divider, List, Typography } from "antd";
import React, {
  Component,
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import ReactHtmlParser, {
  convertNodeToElement,
  processNodes,
} from "react-html-parser";
import { browserName, browserVersion } from "react-device-detect";
import { css, jsx } from "@emotion/react";
import { getPackageAPI, getServiceAPI } from "api/api";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import Axios from "axios";
import { Base64 } from "js-base64";
import Footer from "controllers/components/Footer";
import Header from "controllers/components/Header";
import { InputNumber } from "antd";
import { Link } from "react-router-dom";
import { Package } from "interfaces/package";
import { Service } from "interfaces/service";
import _ from "lodash";
import config from "config.js";
import log from "common/log.js";
import randomstring from "randomstring";
import { scrollTop } from "common/helper";
import { selectConfig } from "features/config/configSlice";
import useDeviceQuery from "common/device";
import { useMediaQuery } from "react-responsive";

export default function Terms(props: any) {
  const {
    isDesktopOrLaptop,
    isTablet,
    isMobile,
    isIpadMini,
    isMobileAndIpadMini,
  } = useDeviceQuery();



  const siteConfig = useSelector((state) => selectConfig(state));
  return (
    <Fragment>
      <Header logined />
      <div className="bg-gray-200">
        <div
          className="container mx-auto pt-8 pb-16"
          css={{ minHeight: 600 }}
        >
          <div
            className="rounded-md border border-gray-300 item duration-500 bg-white"
            css={{ minHeight: 500 }}
          >
            <div className="p-8">
              {siteConfig ? ReactHtmlParser(siteConfig?.terms_of_service) : ""}
            </div>
          </div>    </div>    </div>

      <Footer />
    </Fragment>
  );
}
