/** @jsx jsx */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/** @jsxRuntime classic */

import {
  Affix,
  Breadcrumb,
  Button,
  Card,
  Col,
  Input,
  Layout,
  Menu,
  Row,
  Select,
} from "antd";
import { Divider, List, Typography } from "antd";
import React, {
  Component,
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { TransactionStates, UserTransactions } from "interfaces/user_transactions";
import { browserName, browserVersion } from "react-device-detect";
import { css, jsx } from "@emotion/react";
import { getCategoriesAPI, getServicesAPI, getTrendingAPI, getUserTransactions } from "api/api";
import { getPackageAPI, getServiceAPI } from "api/api";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import Axios from "axios";
import { Base64 } from "js-base64";
import Footer from "controllers/components/Footer";
import Header from "controllers/components/Header";
import ImageUploading from "react-images-uploading";
import { InputNumber } from "antd";
import Lightbox from 'react-image-lightbox';
import { Link } from "react-router-dom";
import { Package } from "interfaces/package";
import _ from "lodash";
import config from "config.js";
import log from "common/log.js";
import moment from 'moment';
import randomstring from "randomstring";
import { scrollTop } from "common/helper";
import { selectAuth } from "features/auh/authSlice";
import useDeviceQuery from "common/device";
import { useMediaQuery } from "react-responsive";

interface ParamTypes {
  transactionId?: string | undefined;
}

export default function TransactionDetail(props: any) {
  const {
    isDesktopOrLaptop,
    isTablet,
    isMobile,
    isIpadMini,
    isMobileAndIpadMini,
  } = useDeviceQuery();

  let { transactionId } = useParams<ParamTypes>();
  const [billImages, setBillImages] = React.useState([]);
  const currentAuth = useSelector((state) => selectAuth(state));
  const [userTransactions, setUserTransactions] = useState<UserTransactions | null>(null);


  const [isOpen, setIsOpen] = useState(false);
  const [isUserImageOpen, setIsUserImageOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [userPhotoIndex, setUserPhotoIndex] = useState(0);


  const onBillImageChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setBillImages(imageList);
    if (transactionId) {
      for(let i = 0; i < imageList.length; i++) {
        const formData = new FormData();
        formData.append("images", imageList[i].file);
        formData.append("transactionId", transactionId);
        Axios.post(config.api.add_user_image, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${currentAuth?.token}`
          },
        }).then((res) => {
          console.log(res);
        });
      }
    }


  };

  useEffect(() => {
    scrollTop();

    if (currentAuth.token && !userTransactions) {
      getUserTransactions({
        headers: {
          Authorization: `Bearer ${currentAuth.token}`,
        },
      }).then((response) => {
        setUserTransactions(response.data)
      })
    }

  }, [currentAuth, userTransactions])

  if (userTransactions) {
    return <Fragment>
      <Header logined />
      <div className="bg-gray-200">
        <div className="container mx-auto pt-8 pb-16">
          <div className="rounded-md border border-gray-300 item duration-500 bg-white" css={{ minHeight: 500 }}>
            <div className="p-8 text-2xl"><i className="ri-shopping-cart-2-fill"></i> <strong>Order:</strong>  BG{transactionId}</div>
            <div >

              <div className="bg-gray-200 px-8 py-2">
                <div className="bm-columns font-bold">
                  <div className="bm-column">DETAIL</div>
                  <div className="bm-column">PAYMENT GUIDE</div>

                  <div className="bm-column">UPDATE BILL</div>
                </div>
              </div>
              {userTransactions.data.map((item, index) => {

                if (item.transaction.id == parseInt(transactionId!)) {
                  let userImages = JSON.parse(item.transaction.user_images) || []
                  return <Fragment>

                    <div className={`px-8 py-8 hover:bg-gray-100`}>
                      <div className="bm-columns">
                        <div className="bm-column">
                          <strong>Order ID:</strong> BG{item.transaction.id}<br />
                          <strong>Date:</strong> {moment(new Date(item.transaction.created_at)).format('DD/MM/YYYY HH:mm:ss')}
                          <br />    <br />
                          <div className="text-2xl" > <strong>Total Price: {item.transaction.total_price}$</strong></div><br />
                          {item.details.map((detail, index_) => {
                            return <Fragment>
                              <div><strong>Service:</strong> {detail.service.name}</div>
                              <div><strong>Package:</strong> {detail.package.name}</div>
                              <div><strong>Buy quantity:</strong> {detail.item.quantity}</div>
                              <div><strong>Buy price:</strong> {detail.item.price} $</div>
                              {index_ + 1 < item.details.length ? <div className="border-b"></div> : ""}

                            </Fragment>
                          })}</div>
                        <div className="bm-column">
                          <strong>Please send the payment to the following account:</strong><br />

                          <table className="bm-table bm-is-bordered bm-is-striped mt-4">
                            <thead>
                              <tr>

                                <th>Account</th>

                                <th>Address</th>
                              </tr>
                              <tr>
                                <td>USDT Binance</td><td>npdong0225@gmail.com</td>
                              </tr>

                              <tr>
                                <td>USDT TRC20</td><td>TUow5qb3Dnn53E4mE8y1f6vtqMzATTEFDE</td>
                              </tr>

                              <tr>
                                <td>Paypal</td><td>trantheanh@me.com</td>
                              </tr>
                            </thead>
                          </table>

                          {userImages.length > 0 ? <Fragment>
                        <strong>User Images</strong>:<br /><br />{userImages && userImages.length > 0 ? userImages.map((e: string, index: number) => {

                          return <Fragment>
                            <img src={`${config.backend}/public/uploads/user-transactions/${e}`} onClick={() => {
                              setIsUserImageOpen(true); setUserPhotoIndex(index)
                              // console.log(JSON.parse(item.transaction.images))
                            }} />


                            <br /><br /></Fragment>
                        }) : ""}

                        {isUserImageOpen && userImages.length > 0 ? (
                          <Lightbox mainSrc={`${config.backend}/public/uploads/user-transactions/${userImages[userPhotoIndex]}`}

                            nextSrc={`${config.backend}/public/uploads/user-transactions/${userImages[(userPhotoIndex + 1) % userImages.length]}`}
                            prevSrc={`${config.backend}/public/uploads/user-transactions/${userImages[(userPhotoIndex + userImages.length - 1) % userImages.length]}`}

                            onMovePrevRequest={() =>
                              setUserPhotoIndex((userPhotoIndex + userImages.length - 1) % userImages.length)
                            }
                            onMoveNextRequest={() =>
                              setUserPhotoIndex((userPhotoIndex + 1) % userImages.length)
                            }

                            onCloseRequest={() => { setIsUserImageOpen(!isOpen) }} />
                        ) : ""}
                        </Fragment> : ""}



                        </div>
                        <div className="bm-column">


                          <ImageUploading
                            multiple
                            value={billImages}
                            onChange={onBillImageChange}
                            maxNumber={10}
                            dataURLKey="data_url"
                            acceptType={["jpg"]}
                          >
                            {({
                              imageList,
                              onImageUpload,
                              onImageRemoveAll,
                              onImageUpdate,
                              onImageRemove,
                              isDragging,
                              dragProps
                            }) => (
                              // write your building UI
                              <div className="upload__image-wrapper">
                                <button
                                  className="bm-button bm-is-normal bm-is-success "
                                  onClick={onImageUpload}
                                  {...dragProps}
                                >
                                  Click to upload Bill Image
                                </button>
                                &nbsp;
                                {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
                                {imageList.map((image, index) => (
                                  <div key={index} className="image-item mt-10">
                                    <img src={image.data_url} alt="" width="200" />
                                    <div className="image-item__btn-wrapper">
                                      {/* <button onClick={() => onImageUpdate(index)}>Update</button> */}
                                      <button onClick={() => onImageRemove(index)}>Remove</button>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </ImageUploading>
                        </div>

                      </div>
                    </div>
                  </Fragment>
                }
              })}

            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Fragment>
  }

  return (
    <Fragment>
      <Header logined />
      <div className="bg-gray-200">
        <div
          className="container mx-auto pt-8 pb-16"
          css={{ minHeight: 600 }}
        ></div>
      </div>

      <Footer />
    </Fragment>
  );
}
