/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  jsx-a11y/anchor-is-valid */
/** @jsxRuntime classic */
/** @jsx jsx */

import {
  Affix,
  Breadcrumb,
  Button,
  Card,
  Col,
  Input,
  Layout,
  Menu,
  Row,
  Select,
} from "antd";
import { Divider, List, Typography } from "antd";
import { Field, Form, Formik, FormikErrors, FormikProps, FormikTouched, withFormik } from 'formik';
import { Package, PackageDataProps } from "interfaces/package";
import React, {
  Component,
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { browserName, browserVersion } from "react-device-detect";
import { css, jsx } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";

import { Base64 } from "js-base64";
import { InputNumber } from "antd";
import { Link } from "react-router-dom";
import _ from "lodash";
import config from "config.js";
import log from "common/log.js";
import { postBuyPakcage } from "api/api";
import randomstring from "randomstring";
import { selectAuth } from "features/auh/authSlice";
import useDeviceQuery from "common/device";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

export default function PackageBuy(props: PackageDataProps) {
  const {
    isDesktopOrLaptop,
    isTablet,
    isMobile,
    isIpadMini,
    isMobileAndIpadMini,
  } = useDeviceQuery();

  let history = useHistory();
  let { service, servicePackage } = props;

  const currentAuth = useSelector((state) => selectAuth(state));

  let price = servicePackage?.user_price;
  if (currentAuth?.profile?.type === "partner") {
    price = servicePackage?.partner_price;
  }

  const [amount, setAmount] = useState<number>(1);
  const [showInfoForm, setShowInfoForm] = useState<boolean>(false);
  const [totalPrice, setTotalPrice] = useState<number>(1 * price);




  let cssRoundClass = !isMobileAndIpadMini ? "rounded-md " : "";
  let paddingRightLeftIpad = isMobileAndIpadMini ? "pr-8 pl-8" : ""
  let chatURL = "https://tawk.to/chat/61398f24d326717cb6807e6f/1ff4dea8v"
  let mobileBottomStyle: any = {
    transform: "translate3d(0,0,0)",
    position: "fixed",
    bottom: 0,
    zIndex: 999,
    width: "100%",
    backgroundColor: "#fff",
    padding: "0 20px !important",
    border: "1px solid #ccc",
  }

  const increasePrice = () => {
    let newAmount = amount + 1;
    setAmount(newAmount);
    setTotalPrice(newAmount * price)
  }

  const decreasePrice = () => {
    let newAmount = amount - 1;
    if (newAmount < 0) {
      newAmount = 0;
    }
    setAmount(newAmount);
    setTotalPrice(newAmount * price)
  }

  const BuyBoxHeader = () => {
    return <div
      className={`bg-green-500 p-4 ${paddingRightLeftIpad} `}
    >
      <div className="flex flex-row text-white justify-between">
        <div className="pt-3">Unit price</div>
        <div className="flex flex-row">
          <div className="font-bold text-2xl mr-2">
            {price}
          </div>
          <div className="pt-3">USD / {servicePackage?.unit_label}</div>
        </div>
      </div>
    </div>
  }

  const BuyBoxData = () => {
    return <Fragment><div className={`p-4 ${paddingRightLeftIpad}`}>
      <div className="flex flex-col">
        <div className="flex flex-row justify-between pb-2">
          <div className="text-gray-500">Delivery speed</div>
          <div className="font-bold text-black">{servicePackage?.eta}</div>
        </div>
        <div className="flex flex-row justify-between pb-2">
          <div className="text-gray-500">Stock</div>
          <div className="font-bold text-black">
            {servicePackage?.quantity} {servicePackage?.unit_label}
          </div>
        </div>
      </div>
    </div>
      <Divider css={{ margin: "4px 0" }} />
    </Fragment>
  }

  const BuyBoxPrice = () => {
    return <Fragment><div
      className={`p-4 flex flex-row ${paddingRightLeftIpad}`}
    >
      <button
        className="bm-button bm-is-medium mr-4 hover:shadow-lg hover:font-bold duration-500"
        onClick={decreasePrice}
      >
        -
      </button>
      <InputNumber
        min={0}
        max={servicePackage?.quantity}
        value={amount}
        defaultValue={amount}
        css={{ width: "100%" }}
        size={"large"}
      />
      <button
        className="bm-button bm-is-medium ml-4 hover:shadow-lg hover:font-bold duration-500"
        onClick={increasePrice}
      >
        +
      </button>
    </div>
      <div
        className={`bg-gray-100 p-4 ${paddingRightLeftIpad}`}
      >
        <div className="flex flex-row text-black justify-between">
          <div className="pt-2 text-gray-500">Price</div>
          <div className="flex flex-row">
            <div className="font-bold text-xl mr-2">{totalPrice}</div>
            <div className="pt-2">USD</div>
          </div>
        </div>
      </div></Fragment>
  }

  const UserInfoForm = (props: UserInfoFormProps) => {
    if (showInfoForm) {
      return <Fragment>
        <div className="p-4" css={{ position: "relative" }}>
          <i className="ri-close-line"
            css={{ fontSize: 26, position: "absolute", right: 8, top: 8 }}
            onClick={() => {
              setShowInfoForm(false)
            }}></i>
          <br /><br />

          <p className="text-xs sm:text-sm text-gray-600">Fill in your account information</p>

          <div className="flex flex-col mb-6">
            <label
              htmlFor="name"
              className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
            >
              Notes: (Optional)
            </label>
            <div className="relative">
              <input
                id="note"
                type="text"
                name="note"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.note}
                className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4
                      rounded-lg border border-gray-400 w-full py-2
                      focus:outline-none focus:border-blue-400"
                placeholder="Note"
              />
            </div>
          </div>

          <div className="flex flex-col mb-6">
            <label
              htmlFor="name"
              className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
            >
              Username:
            </label>
            <div className="relative">
              <input
                id="username"
                type="text"
                name="username"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.username}
                className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4
                      rounded-lg border border-gray-400 w-full py-2
                      focus:outline-none focus:border-blue-400"
                placeholder="Username"
              />
            </div>
          </div>

          <div className="flex flex-col mb-6">
            <label
              htmlFor="name"
              className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
            >
              Password:
            </label>
            <div className="relative">
              <input
                id="password"
                type="text"
                name="password"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.password}
                className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4
                      rounded-lg border border-gray-400 w-full py-2
                      focus:outline-none focus:border-blue-400"
                placeholder="password"
              />
            </div>
          </div>


          <div className="flex flex-col mb-6">
            <label
              htmlFor="name"
              className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
            >
              Login Method:
            </label>
            <div className="relative">
              <input
                id="loginMethod"
                type="text"
                name="loginMethod"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.loginMethod}
                className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4
                      rounded-lg border border-gray-400 w-full py-2
                      focus:outline-none focus:border-blue-400"
                placeholder="Login Method"
              />
            </div>
          </div>

          <div className="flex flex-col mb-6">
            <label
              htmlFor="name"
              className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
            >
              2FA Code:
            </label>
            <div className="relative">
              <input
                id="faCode"
                type="text"
                name="faCode"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.faCode}
                className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4
                      rounded-lg border border-gray-400 w-full py-2
                      focus:outline-none focus:border-blue-400"
                placeholder="Code"
              />
            </div>
          </div>

          <div className="flex flex-col mb-6">
            <label
              htmlFor="name"
              className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
            >
              Character:
            </label>
            <div className="relative">
              <input
                id="character"
                type="text"
                name="character"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.character}
                className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4
                      rounded-lg border border-gray-400 w-full py-2
                      focus:outline-none focus:border-blue-400"
                placeholder="Character"
              />
            </div>
          </div>


          <div className="flex flex-col mb-6">
            <label
              htmlFor="name"
              className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
            >
              Server:
            </label>
            <div className="relative">
              <input
                id="server"
                type="text"
                name="server"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.server}
                className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4
                      rounded-lg border border-gray-400 w-full py-2
                      focus:outline-none focus:border-blue-400"
                placeholder="Server"
              />
            </div>
          </div>


          <p><strong>Important Note:</strong> Do not return the goods under any circumstances and regardless of the reasons given, even if it's the same character that just traded with you. Report to us if anyone ask you to do so.</p>
          <p><strong>Important Note:</strong> Any changes to the delivery method must be mutually agreed upon between buyer and seller, and the party requesting for the changes must bear all transaction fee, cost or tax incurred.</p>
          <div className='mt-4'>
            <strong>Please send the payment to the following account:</strong><br />

            <table className="bm-table bm-is-bordered bm-is-striped mt-4">
              <thead>
                <tr>

                  <th>Account</th>

                  <th>Address</th>
                </tr>
                <tr>
                  <td>USDT Binance</td><td>npdong0225@gmail.com</td>
                </tr>

                <tr>
                  <td>USDT TRC20</td><td>TUow5qb3Dnn53E4mE8y1f6vtqMzATTEFDE</td>
                </tr>

                <tr>
                  <td>Paypal</td><td>trantheanh@me.com</td>
                </tr>
              </thead>
            </table>



          </div>

        </div>

      </Fragment>
    }

    return <Fragment></Fragment>
  }

  const handleBuyClick = () => {
    if (!showInfoForm) {
      setShowInfoForm(true)
    } else {

    }
  }

  const BuyButton = (props: BuyButtonProps) => {

    if (currentAuth.profile) {
      if (!isMobileAndIpadMini) {
        return <div className="p-4">
          <button className="bm-button bm-is-warning bm-is-fullwidth bm-is-large
          hover:shadow-lg hover:font-bold duration-500"
            onClick={handleBuyClick}>
            Buy Now
          </button>
        </div>
      }

      return <Fragment>
        <Affix offsetBottom={0}>
          <Layout.Header
            className={`transition duration-150 ease-in-out desktop-header `}
            css={mobileBottomStyle}
          >
            <div className="flex flex-row justify-between">
              <a
                href={chatURL}
                target="_blank" rel="noreferrer"
                className="bm-button bm-is-gray bm-is-fullwidth mt-3 mr-4"
              >
                <i className="fas fa-comment-dots text-green-500 mr-2"></i>
                Chat
              </a>
              <button
                className="bm-button bm-is-warning bm-is-fullwidth mt-3 "
                onClick={handleBuyClick}
              >
                Buy Now
              </button>
            </div>
          </Layout.Header>
        </Affix>
      </Fragment>
    }

    else {
      if (!isMobileAndIpadMini) {
        return <div className="p-4">
          <button
            className="bm-button bm-is-warning bm-is-fullwidth bm-is-large
            hover:shadow-lg hover:font-bold duration-500"
            onClick={() => { history.push("/login") }}>
            Login to Buy
          </button>
        </div>
      }

      return <Fragment>
        <Affix offsetBottom={0}>
          <Layout.Header
            className={`transition duration-150 ease-in-out desktop-header `}
            css={mobileBottomStyle}
          >
            <div className="flex flex-row justify-between">
              <a
                href={chatURL}
                target="_blank" rel="noreferrer"
                className="bm-button bm-is-gray bm-is-fullwidth mt-3 mr-4"
              >
                <i className="fas fa-comment-dots text-green-500 mr-2"></i>
                Chat
              </a>
              <button
                className="bm-button bm-is-warning bm-is-fullwidth mt-3"
                onClick={() => { history.push("/login") }}
              >
                Login to Buy
              </button>
            </div>
          </Layout.Header>
        </Affix>
      </Fragment>
    }

  }

  const formValues: UserInfoValues = {
    note: "", packageId: servicePackage?.id, faCode: "",
    server: "", character: "", username: "", password: "", loginMethod: ""
  };

  return (
    <div
      className={`${cssRoundClass} border border-gray-300 package-buy overflow-hidden duration-500 bg-white`}
      css={{}}
    >
      <BuyBoxHeader />
      <BuyBoxData />
      <BuyBoxPrice />

      <Formik
        initialValues={formValues}
        validate={(values) => {
          const errors: FormikErrors<UserInfoValues> = {};
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          let data: PostData = {
            "note": values.note,
            "details": [
              {
                "packageId": values.packageId,
                "quantity": amount,
                "loginMethod": values.loginMethod,
                "faCode": values.faCode,
                "server": values.server,
                "username": values.username,
                "password": values.password,
                "character": values.character
              },

            ]
          }
          let result: any = await postBuyPakcage(data, {
            headers: {
              Authorization: `Bearer ${currentAuth.token}`,
            },
          }).then((response) => {
            return response.data;
          })
          if (result.transactionId) {
            window.location.href = `/user/transaction/${result.transactionId}`
            // alert(result.message)
          }
          console.log(values)
        }}
      >

        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <UserInfoForm values={values} errors={errors}
              touched={touched} handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <BuyButton isSubmitting={isSubmitting} />
          </form>)}
      </Formik>



    </div>
  );
}



export interface PostData {
  note: string
  details: Detail[]
}

export interface Detail {
  packageId: number
  quantity: number
  faCode: string
  loginMethod: string
  server: string
  username: string
  password: string
  character: string
}


export interface BuyButtonProps {
  isSubmitting: boolean
}


export interface UserInfoFormProps {
  values: UserInfoValues;
  errors: FormikErrors<UserInfoValues>;
  touched: FormikTouched<UserInfoValues>;
  handleChange: any;
  handleBlur: any;
}




interface UserInfoValues {
  note: string;
  packageId: number;
  loginMethod: string;
  faCode: string;
  server: string;
  username: string;
  password: string;
  character: string;
}
